import moment from "moment-timezone";
import { Modal } from "bootstrap";

interface ViewSlots {
  selectedAppointments: any;
  consumerChoice: string;
  selectedPlan: number;
  specialistData: any;
  selectedTimezone: any;
  userTimezone: any;
}

const ViewSelectedSlots: React.FC<ViewSlots> = ({
  consumerChoice,
  selectedAppointments,
  selectedPlan,
  specialistData,
  selectedTimezone,
  userTimezone,
}) => {
  return (
    <div>
      <div
        className="mr-5 text-white"
        data-bs-target="#bookSlotPopUp"
        data-bs-toggle="modal"
        data-bs-dismiss="modal"
        onClick={() => {
          const currentModalElement =
            document.getElementById("viewSelectedSlots");

          if (currentModalElement) {
            const currentModal =
              Modal.getInstance(currentModalElement) ||
              new Modal(currentModalElement);
            currentModal.hide();
          }

          const previousModalElement = document.getElementById("bookSlotPopUp");

          if (previousModalElement) {
            const previousModal =
              Modal.getInstance(previousModalElement) ||
              new Modal(previousModalElement, { backdrop: false });
            previousModal.show();
          }
        }}
      >
        <button
          className="btn book-btn book-btn-new min-height-40 subscription_plan_back_button"
          type="button"
        >
          BACK
        </button>
      </div>

      <label
        className="form-check-label text-center mb-0 text-white explore-heading-two w-100 pb-3"
        htmlFor="exampleCheck1"
      >
        <span className="d-block h4 text-start pt-4 mb-0 text-center">
          {consumerChoice === "subscription"
            ? `SUBSCRIPTION APPOINTMENT PLAN -  ${selectedAppointments.length} / ${selectedPlan}`
            : "SINGULAR APPOINTMENT OPTIONS"}
        </span>
        <span className="available-slot d-block h6">
          {`SELECTED ${
            selectedAppointments?.length === 1 ? `SLOT` : `SLOTS`
          } FOR - DR. ${specialistData?.name?.toUpperCase()}`}
        </span>
      </label>
      {/* Card */}
      <div className="row">
        {selectedAppointments.length > 0 ? (
          selectedAppointments.map((item: any, index: any) => (
            <div className="col-lg-6 pb-3" key={index}>
              <div className="slot-box-inner border p-3">
                <p className="text-uppercase text-white book__text mb-0 text-left">
                  Day: <span>{item?.calendarDay}</span>
                </p>
                <p className="text-uppercase text-white book__text mb-0 text-left">
                  START TIME:{" "}
                  {moment
                    .tz(item?.StartTime?.replace(/:000Z$/, ":00Z"), "UTC")
                    .tz(selectedTimezone?.value || userTimezone)
                    .format("hh:mm A")}{" "}
                  - END TIME:{" "}
                  {moment
                    .tz(item?.EndTime?.replace(/:000Z$/, ":00Z"), "UTC")
                    .tz(selectedTimezone?.value || userTimezone)
                    .format("hh:mm A")}
                </p>

                <p className="text-uppercase text-white book__text mb-0 text-left">
                  Duration:{" "}
                  <span>
                    {item?.Duration === 0.5
                      ? "30 MIN"
                      : item?.Duration
                      ? "60 MIN"
                      : `${item?.Duration}`}{" "}
                  </span>
                </p>
                <p className="text-uppercase text-white book__text mb-0 text-left">
                  Date : <span>{item?.calendarDate}</span>
                </p>
              </div>
            </div>
          ))
        ) : (
          <>
            <div>
              <p className="text-uppercase text-white book__text mb-0 text-left">
                NO DATA FOUND
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewSelectedSlots;
