const PaymentUnderProgressUI = () => {
  return (
    <div>
      <div className="processing-container">
        <div className="processing-content">
          <h1 className="processing-text letter_spacing">
            PROCESSING SECURE PAYMENT CONNECTION...
          </h1>
          <div className="processing-animation">
            <span className="bubble "></span>
            <span className="bubble ps-2"></span>
            <span className="bubble ps-2"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentUnderProgressUI;
