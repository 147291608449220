import { Rating } from "react-simple-star-rating";

interface RatingData {
  doctorRating: {
    average_rating: string;
    doctor_total_rating_count: number;
    total_rating_count: number;
  }[];
  doctorReviews: {
    id: number;
    rating: string;
    review: string;
    createdOn: string;
    isApproved: number;
  }[];
  ratingWisePercentageAndCounts: {
    rating: string;
    rating_count: number;
    rating_percentage: string;
  }[];
}

interface ViewReviewProps {
  ratingData: RatingData | null;
}
const ViewReview: React.FC<ViewReviewProps> = ({ ratingData }) => {
  const averageRating =
    Number(ratingData?.doctorRating[0]?.average_rating)?.toFixed(1) || "0";
  const totalRatings = ratingData?.doctorRating[0]?.total_rating_count || 0;
  const doctorTotalRatings =
    ratingData?.doctorRating[0]?.doctor_total_rating_count || 0;
  return (
    <div>
      {" "}
      <div
        className="modal  p-0 gloabl-model-settings"
        id="reviewModalOpen2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel3"
        tabIndex={-1}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content user_response--popup p-2 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center my-0 py-0">
              <div className="customer-reviews w-100">
                <ul className="d-flex  ps-0 justify-content-between">
                  <li className="d-flex align-items-center">
                    <h6 className="text-white text-uppercase mb-0 explore-heading-two h5">
                      Customer review
                    </h6>
                  </li>
                  <li className="star-group star-group-outer d-flex align-items-center gap-3">
                    <div className="ratting-outer-box ratting-outer-box-review">
                      <span className="explore-heading-one explore-heading-one text-white d-flex align-items-center pe-2">
                        {averageRating}
                      </span>
                      <Rating
                        size={30}
                        // onClick={(value: any) => {
                        //   checkFilter(elem, value);
                        //   setIntialVal(value);
                        // }}
                        fillColor="white"
                        emptyColor="black"
                        SVGstorkeWidth="1px"
                        SVGstrokeColor="white"
                        allowFraction
                        style={{
                          marginBottom: "0px",
                          paddingBottom: "0px",
                        }}
                        initialValue={Number(
                          ratingData?.doctorRating[0]?.average_rating
                        )}
                        className="stat-rating"
                      />
                      <span className="explore-heading-one text-white d-inline text-uppercase d-flex align-items-center ps-2">
                        ({doctorTotalRatings})
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <hr className="custom-white-hr w-100 mt-0" />
              <div className="w-100 d-block">
                <label className="text-white text-start explore-heading-two w-100">
                  {totalRatings} GLOBAL RATING
                </label>

                {/* Ratings Breakdown */}
                {ratingData?.ratingWisePercentageAndCounts.map(
                  (item, index) => (
                    <div
                      className="d-flex align-items-center mb-2 custom-progress-bar"
                      key={index}
                    >
                      <span className="me-2 text-white d-flex star-custom">
                        {item.rating} Star
                      </span>{" "}
                      {/* Star icon */}
                      <div className="w-100">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${item.rating_percentage}%` }}
                            aria-valuenow={parseFloat(item?.rating_percentage)}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      </div>
                      <span className="percentage percentage-custom ps-2 text-white">
                        {parseFloat(item.rating_percentage).toFixed(1)}%
                      </span>
                    </div>
                  )
                )}
              </div>
              <div className="submit-btn-custom w-100 d-flex justify-content-end">
                {/* <button
                  className="btn book-btn book-btn-new d-block mb-0 mt-4 min-height-40"
                  data-bs-target="#specialistDetails2"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  type="button"
                >
                  SUBMIT
                </button> */}
              </div>
            </div>
            <div
              className="cancel__pop__icon"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewReview;
