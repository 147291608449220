interface SpecialistData {
  specialistData: any;
}

const SpecialistInfo: React.FC<SpecialistData> = ({ specialistData }) => {
  const showExpData = (type: string) => {
    const language =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].language
        : "";
    const areaOfExpertise =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].areaofexpertise
        : "";
    const title =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].title
        : "";
    let parsedLanguage: any;
    let parsedAreaOfExp: any;

    if (language && areaOfExpertise) {
      parsedLanguage = JSON.parse(language);
      parsedAreaOfExp = JSON.parse(areaOfExpertise);
    }

    if (parsedLanguage && type === "language") {
      return parsedLanguage.join("|");
    }
    if (parsedAreaOfExp && type === "areaofexpertise") {
      return parsedAreaOfExp.map((elem: any) => elem.category).join(" | ");
    }
    return title;
  };
  return (
    <div>
      {" "}
      <div className="row pb-2 mobile-flex-wrap">
        <div className="col max-width-225 height-219">
          <div className="specialist-image specialist-image-box">
            <img
              src={specialistData?.profile}
              alt=""
              className="img-fluid rounded"
            />
          </div>
        </div>
        <div className="col">
          <div className="subscription-group subscription-group-new">
            <ul className="ps-0">
              <li>
                <div className="subscription-group-inner">
                  <div className="subscription-part-two">
                    <p className="explore-heading-one text-white mb-0 text-uppercase font-size-24">
                      {specialistData?.name}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="subscription-group-inner">
                  <div className="subscription-part-two">
                    <p className="explore-heading-one text-white mb-0 text-uppercase">
                      {specialistData?.gender}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="subscription-group-inner">
                  <div className="subscription-part-two">
                    <p className="explore-heading-one text-white mb-0 text-uppercase">
                      {specialistData &&
                      specialistData?.DoctorExperiences &&
                      specialistData?.DoctorExperiences.length
                        ? specialistData?.DoctorExperiences[0].title
                        : null}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="subscription-group-inner">
                  <div className="subscription-part-two">
                    <p className="explore-heading-one text-white mb-0 text-uppercase font-size-20">
                      {showExpData("areaofexpertise")}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="subscription-group-inner">
                  <div className="subscription-part-two">
                    <p className="explore-heading-one text-white mb-0 text-uppercase">
                      Bio info
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="subscription-group-inner">
                  <div className="subscription-part-two">
                    <p className="text-white mb-0 text-uppercase explore-heading-one mb-0 text-uppercase">
                      {specialistData.length !== 0 &&
                        specialistData?.DoctorExperiences[0]
                          .yearofexperience}{" "}
                      YEARS EXPERIENCE
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="subscription-group-inner">
                  <div className="subscription-part-two">
                    <p className="explore-heading-one text-white mb-0 mb-0-custom text-uppercase">
                      {showExpData("language")}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialistInfo;
