import ShimmerUI from "../Shimmer";
import usePuckEditorByPageName from "../../customHooks/usePuckEditorByPageName";

const withTitle = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    const { puckData, puckloader } = usePuckEditorByPageName("FAQ");
    const faqData = puckData?.content?.find((elem: any) => elem.type === "Faq");
    const { title } = props;

    return puckloader ? (
      <ShimmerUI />
    ) : (
      <section className="pb-0 faq-wrapper">
        <div className="container-fluid left-right-space">
          <div className="row py-4">
            <div className="col-lg-12">
              <h1 className="text-center mb-md-0">
                {faqData?.props?.title ? faqData?.props?.title : title}
              </h1>
            </div>
          </div>
          <WrappedComponent {...props} />
        </div>
      </section>
    );
  };
};

export default withTitle;
