import { Rating } from "react-simple-star-rating";
import { useState } from "react";

interface WriteReviewProps {
  globalRating: any;
  reviewData: {
    rating: number;
    reviewText: string;
  };
  setReviewData: (data: { rating: number; reviewText: string }) => void;
  onSubmit: (data: { rating: number; reviewText: string }) => void; // New prop for API call
}

const WriteReview: React.FC<WriteReviewProps> = ({
  globalRating,
  reviewData,
  setReviewData,
  onSubmit,
}) => {
  // Handle rating change
  const handleRatingChange = (newValue: number) => {
    setReviewData({ ...reviewData, rating: newValue });
  };

  // Handle review text change
  const handleReviewTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setReviewData({ ...reviewData, reviewText: event.target.value });
  };
  const handleSubmit = () => {
    if (!reviewData.reviewText.trim()) {
      alert("Please enter a review before submitting.");
      return;
    }
    onSubmit(reviewData); // API call
  };
  return (
    <div>
      <div
        className="modal p-0 gloabl-model-settings"
        id="reviewModalOpen"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex={-1}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content user_response--popup p-2 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center my-0 py-0">
              <div className="customer-reviews w-100">
                <ul className="d-flex ps-0 justify-content-between">
                  <li className="d-flex align-items-center">
                    <h6 className="text-white text-uppercase mb-0 explore-heading-two h5">
                      Customer review
                    </h6>
                  </li>
                  <li className="star-group star-group-outer d-flex align-items-center gap-3">
                    <div className="ratting-outer-box ratting-outer-box-review">
                      <span className="explore-heading-one text-white d-flex align-items-center pe-2">
                        {reviewData.rating.toFixed(1)}
                      </span>
                      <Rating
                        size={30}
                        fillColor="white"
                        emptyColor="black"
                        SVGstorkeWidth="1px"
                        SVGstrokeColor="white"
                        allowFraction
                        initialValue={reviewData.rating}
                        onClick={handleRatingChange}
                        style={{
                          marginBottom: "0px",
                          paddingBottom: "0px",
                        }}
                        className="stat-rating"
                      />
                      <span className="explore-heading-one text-white d-inline text-uppercase d-flex align-items-center ps-2">
                        {reviewData.rating.toFixed(1)} OUT OF 5
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <hr className="custom-white-hr w-100 mt-0" />
              <div className="w-100 d-block">
                <label className="text-white text-start explore-heading-two w-100">
                  {globalRating} GLOBAL RATING
                </label>
                <textarea
                  className="form-control w-100 custom-height customer-review-textarea custom-placeholder"
                  rows={3}
                  value={reviewData.reviewText}
                  onChange={handleReviewTextChange}
                  placeholder="TYPE HERE"
                />
              </div>
              <div className="submit-btn-custom w-100 d-flex justify-content-end ">
                <button
                  className="btn book-btn book-btn-new d-block mb-0 mt-4 min-height-40 "
                  data-bs-target="#specialistDetails"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  type="button"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </div>
            <div
              className="cancel__pop__icon"
              data-bs-target="#specialistDetails"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              <i className="fa-solid fa-xmark text-white"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteReview;
