import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PaymentUnderProgressUI from "../stripepayment/PaymentUnderProgressUI";

const Checkout: React.FC = () => {
  const { id } = useParams();

  let data = localStorage.getItem("userInfo");
  let userInfo = null;
  let cartId: any = localStorage.getItem("cartId");

  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }
  let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;

  useEffect(() => {
    fetchClientSecret();
    async function fetchClientSecret() {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            orderId: id,
            cartUuid:
              lastActiveCartId && lastActiveCartId !== null
                ? lastActiveCartId
                : cartId,
            success_url: `${process.env.REACT_APP_LOCALHOST_URL}/success/${id}`,
            cancel_url: `${process.env.REACT_APP_LOCALHOST_URL}/cancel/${id}`,
            organizationId: 1,
            action: "command",
            command: [
              {
                agent: "createCheckout",
                appName: "selfMaximized",
                folder: "payment",
              },
            ],
          },
          {
            headers: { "x-access-token": localStorage.getItem("token") },
          }
        );

        if (result?.data?.code === 1) {
          window.location.href = result?.data?.data?.sessionUrl;
        } else {
          console.error("Failed to fetch clientSecret:", result);
        }
      } catch (error) {
        console.error("Error fetching clientSecret:", error);
      }
    }
  }, [id]);

  return (
    <>
      <PaymentUnderProgressUI />
    </>
  );
};
export default Checkout;
