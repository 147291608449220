import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ShimmerUI from "../Shimmer";
import usePuckEditorByPageName from "../../customHooks/usePuckEditorByPageName";

const CancellationPolicy = () => {
  const [cancellationPolicy, setCancellationPolicy] = useState<any>([]);
  const { puckData, puckloader } = usePuckEditorByPageName(
    "CANCELLATION POLICY"
  );
  const cancellationPolicyData: any = puckData?.content?.find(
    (elem: any) => elem.type === "CancellationPolicy"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPolicies();
  }, []);

  interface htmlContent {
    htmlContent: any;
  }
  const HtmlToPoints: React.FC<htmlContent> = ({ htmlContent }) => {
    const sections = htmlContent
      .split(/<\/?p>|<br\s*\/?>|<\/?b>/)
      .filter((section: any) => section.trim() !== "");

    return (
      <div>
        <ol className="">
          {sections.map((section: any, index: any) => (
            <li
              key={index}
              dangerouslySetInnerHTML={{ __html: section }}
              className="mb-3 policies-list"
            />
          ))}
        </ol>
      </div>
    );
  };

  const getPolicies = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
        action: "command",
        command: [
          {
            agent: "getTermsAndConditions",
            appName: "selfMaximized",
            folder: "policies",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCancellationPolicy(
            result?.data?.data?.filter(
              (item: any) => item.pageName === "Cancellation Policy"
            )
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong", err);
      });
  };

  return puckloader ? (
    <ShimmerUI />
  ) : (
    <>
      <section className="pb-0 user-registration-flow-wrapper contact-us_wrapper_title">
        <div className="container-fluid">
          <div className="row py-4">
            <div className="col-lg-12">
              <h1 className="user-registration-title mb-0 text-center">
                {cancellationPolicyData
                  ? cancellationPolicyData?.props?.title
                  : ""}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0 user-registration-flow-wrapper py-0">
        <div className="container-fluid left-right-space">
          <div className="row">
            <div className="col-lg-12">
              <h4
                className="text-uppercase text-white text-start mb-5 ms-5"
                style={{
                  fontSize: "35px",
                  color: " #fff",
                  letterSpacing: "4px",
                  lineHeight: "19px",
                }}
              >
                {cancellationPolicyData
                  ? cancellationPolicyData?.props?.policyForCustomer
                  : ""}
              </h4>
              <p className="mb-0 banner-description  text-uppercase pb-3 text-white">
                {cancellationPolicy.length !== 0 ? (
                  <HtmlToPoints
                    htmlContent={cancellationPolicy[0].pageDataUser}
                  />
                ) : null}
              </p>
            </div>
            <div className="col-lg-12">
              <h4
                className="text-uppercase text-white text-start mb-5 ms-5"
                style={{
                  fontSize: "35px",
                  color: " #fff",
                  letterSpacing: "4px",
                  lineHeight: "19px",
                }}
              >
                {cancellationPolicyData
                  ? cancellationPolicyData?.props?.policyForSpecialist
                  : ""}
              </h4>
              <p className="mb-0 banner-description  text-uppercase pb-3 text-white">
                {cancellationPolicy.length !== 0 ? (
                  <HtmlToPoints
                    htmlContent={cancellationPolicy[0].pageDataSpecialist}
                  />
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CancellationPolicy;
