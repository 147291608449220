import React, { useEffect, useState } from "react";
import usePuckEditorByPageName from "../../customHooks/usePuckEditorByPageName";

interface Product {
  productData: Array<any>;
  onSelectedRange: (data: {
    productUuid: string;
    modeType: string;
    planSelected: number;
    isChecked: boolean;
    type: string;
    sub: boolean;
    subDuration: number;
    stripePriceId: string;
    subProductId: number;
    categoryUuid: string;
  }) => void;
  warningStatus: boolean;
  undoAllSub: boolean;
}

const BookSubscription: React.FC<Product> = React.memo(
  ({ productData, onSelectedRange, undoAllSub, warningStatus }) => {
    const [productUuid, setProductuuid] = useState<string>("");
    const [subProductId, setSubscriptionProductId] = useState<number>(0);
    const [modeType, setModeType] = useState<string>("");
    const [planSelected, setPlanSelected] = useState<number>(0);
    const [isChecked, setIsChecked] = useState<any>(false);
    const [type, setType] = useState<string>("subscription");
    const [subDuration, setSubProductDuration] = useState<number>(0);
    const [stripePriceId, setStripePriceId] = useState<string>("");
    const [categoryUuid, setCategoryUuid] = useState<string>("");
    const [sub, setSub] = useState<boolean>(true);
    const { puckData } = usePuckEditorByPageName("BOOK SPECIALIST");

    useEffect(() => {
      if (undoAllSub) {
        setSub(!sub);
        setProductuuid("");
        setModeType("");
        setPlanSelected(0);
        setStripePriceId("");
        setSubProductDuration(0);
        setIsChecked(false);
        setSubscriptionProductId(0);
        setCategoryUuid("");
      }
    }, [undoAllSub]);

    useEffect(() => {
      onSelectedRange({
        productUuid,
        modeType,
        planSelected,
        isChecked,
        type,
        sub,
        subDuration,
        stripePriceId,
        subProductId,
        categoryUuid,
      });
    }, [
      productUuid,
      modeType,
      planSelected,
      isChecked,
      sub,
      subDuration,
      stripePriceId,
      subProductId,
      categoryUuid,
    ]);

    return (
      <>
        <div
          className={`col singular-appointment-wrapper singular-appointment-wrapper-new ${
            productUuid || modeType || planSelected || subDuration
              ? "active"
              : ""
          }`}
        >
          <div className="mb-3 form-check form-check-custom">
            <input
              className="form-check-input  custom-checkbox custom-checkbox-new mb-1"
              type="checkbox"
              style={{
                width: "16px",
                height: "16px",
                outline: "none",
                borderRadius: "0",
              }}
              id="exampleCheck1"
              onClick={() => {
                setIsChecked((prev: any) => !prev);
              }}
              checked={isChecked}
            />
            <label className="form-check-label mb-0" htmlFor="exampleCheck1">
              SUBSCRIPTION OPTIONS :{" "}
              <span className="custom-save-label p">(SAVE 10%)</span>
            </label>
            <p
              className={`explore-heading-one ${
                warningStatus && planSelected === 0
                  ? "text-danger"
                  : "text-white"
              } text-uppercase mb-2`}
            >
              {puckData?.props?.subscripitionPlan ?? "PLEASE SELECT PLAN"}
            </p>
            <div className="month-group d-flex gap-4 pe-lg-4 pe-md-4 pe-0">
              {[2, 4].map((num) => (
                <div
                  key={num}
                  className={`d-md-flex justify-content-between align-items-center py-2 px-3 mb-2 cursor_pointer flex-1 inner-month-box ${
                    planSelected === num && sub ? "active-month" : ""
                  }`}
                  style={{ border: "1px solid white" }}
                  onClick={() => {
                    setPlanSelected((prev) => (prev === num ? 0 : num));
                    setSub(true);
                    setIsChecked(true);
                  }}
                >
                  <p
                    className="mb-0 appointment-text-one"
                    style={{ color: "white" }}
                  >
                    {num} PER MONTH
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="px-4">
            <p
              className={`explore-heading-one ${
                subProductId === 0 && warningStatus
                  ? "text-danger"
                  : "text-white"
              } text-uppercase mb-2`}
            >
              {puckData?.props?.appointmentSelection ??
                "PLEASE SELECT APPOINTMENT"}
            </p>
            {productData.length !== 0 && productData !== null
              ? productData.map((elem: any) => (
                  <>
                    <div
                      className={`d-md-flex justify-content-between align-items-center py-2 px-3 mb-2 cursor_pointer`}
                      onClick={() => {
                        setProductuuid((prev: any) =>
                          prev === elem?.uuid ? "" : elem?.uuid
                        );
                        setSubscriptionProductId(elem?.id);
                        setSubProductDuration(elem?.duration);
                        setSub(true);
                        setIsChecked(true);
                        setStripePriceId(elem);
                        setCategoryUuid(elem?.categoryUuid);
                      }}
                      key={elem.id}
                      style={{
                        border: "1px solid white",
                        backgroundColor: `${
                          productUuid === elem.uuid && sub ? "white" : "black"
                        }`,
                      }}
                    >
                      <div className="">
                        <p
                          className="mb-0 appointment-text-one"
                          style={{
                            color: `${
                              productUuid !== elem.uuid ? "white" : ""
                            }`,
                          }}
                        >
                          {parseFloat(elem.duration).toFixed(2) === "1.00"
                            ? 60
                            : 30}
                          <span className="ps-1">
                            {puckData?.props?.productDuration ??
                              "MIN VIRTUAL APPOINTMENT"}
                          </span>
                        </p>
                      </div>
                      <div className="">
                        <div className="package-amount-in-dollar-one px-3 py-1">
                          <div className="d-flex align-items-center">
                            <span
                              className="pe-1"
                              style={{
                                color: productUuid !== elem.uuid ? "white" : "",
                              }}
                            >
                              <i className="fa-solid fa-dollar-sign"></i>
                            </span>
                            <span
                              className="amount-dollar_package-one letter-spacing-pricing"
                              style={{
                                color: productUuid !== elem.uuid ? "white" : "",
                              }}
                            >
                              {elem.price}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : null}

            {productData.length !== 0 && productData !== null ? (
              <>
                <div className="py-3">
                  <p
                    className={` mb-0 font-18 ${
                      modeType === "" && warningStatus
                        ? "text-danger"
                        : "text-white"
                    } please-select-mode`}
                  >
                    {puckData?.props?.productMode ??
                      " PLEASE SELECT MODE OF APPOINTMENT"}
                  </p>
                </div>
                <div className="appointment-duration-box-inner mb-3">
                  {["video", "audio", "chat"].map((type: any) => (
                    <span
                      key={type}
                      className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase"
                      onClick={() => {
                        setModeType((prev) => (prev === type ? "" : type));
                        setSub(true);
                        setIsChecked(true);
                      }}
                      style={{
                        color: modeType === type && sub ? "black" : "white",
                        backgroundColor:
                          modeType === type && sub ? "white" : "black",
                      }}
                    >
                      <i
                        className={`fa-solid fa-${
                          type === "chat" ? "message" : type
                        } text-${modeType === type && sub ? "dark" : "white"}`}
                      ></i>{" "}
                      {type}
                    </span>
                  ))}
                </div>
              </>
            ) : (
              <span className="text-white letter_spacing ">
                NO PRODUCTS AVAILABLE
              </span>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default BookSubscription;
