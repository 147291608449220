import React, { useContext, useEffect } from "react";
import AuthContext from "../UseContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SuccessPage: React.FC = () => {
  const authContext = useContext(AuthContext);
  const userInfo = localStorage.getItem("userInfo");
  const token = localStorage.getItem("token");
  const parsedUserInfo =
    typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
  useEffect(() => {
    authContext.cart(0);
    localStorage.removeItem("cartId");
  }, []);

  const goToDashboard = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          user_id: parsedUserInfo.uuid,
          menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
          action: "command",
          command: [
            {
              agent: "getUserPolicies",
              appName: "selfMaximized",
              folder: "policies",
            },
          ],
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then(async (result) => {
        if (
          result?.data?.statusCode === 200 &&
          result?.data?.data?.length === 0
        ) {
          window.location.href = "/app/#/welcome";
          return;
        } else {
          window.location.href = "/app/#/dashboard";

          return;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <div className="success-container">
      <div className="success-content position-relative p-5">
        <div>
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            {" "}
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />{" "}
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <h2 className="success-title mt-3 mb-0">Payment Successful!</h2>
        <p className="success-message mb-0">
          Thank you for your purchase. Your order has been successfully
          processed.
        </p>
        <button
          className="btn btn-light mt-2 go__to--dashboard"
          onClick={goToDashboard}
        >
          GO TO DASHBOARD
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
