// ApiService.tsx
import axios, { AxiosResponse } from "axios";

interface RatingParams {
  userId?: number;
  doctorId?: number;
  rating?: number;
  review?: string;
}

interface GetRatingsListParams {
  offset?: number;
  limit?: number;
  isApproved?: number;
  filters?: any[];
  excelDownload?: boolean;
}

interface UpdateRatingParams {
  id: number;
  isApproved: number;
}

interface GetDoctorRatingParams {
  doctorId: number;
}

/**
 * Create a new rating for a doctor
 */
export const createRating = async ({
  userId,
  doctorId,
  rating,
  review,
}: RatingParams) => {
  try {
    const requestBody = {
      userId,
      doctorId,
      rating,
      review,
      action: "command",
      command: [
        {
          agent: "createRatings",
          appName: "selfMaximized",
          folder: "ratings",
        },
      ],
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      requestBody
    );

    return response.data;
  } catch (error) {
    console.error("Error creating rating:", error);
    throw error;
  }
};

/**
 * Get a list of ratings with optional filtering
 */
export const getRatingsList = async ({
  offset = 0,
  limit = 10,
  isApproved = 0,
  filters = [],
  excelDownload = false,
}: GetRatingsListParams) => {
  try {
    // Add the isApproved filter if not already present in custom filters
    const hasApprovedFilter = filters.some(
      (filter) => filter.field === "isApproved"
    );

    const defaultFilters = hasApprovedFilter
      ? filters
      : [
          {
            field: "isApproved",
            table: "R",
            operator: "contain",
            value: isApproved,
          },
          ...filters,
        ];

    const requestBody = {
      filter: {
        filter: !excelDownload
          ? {
              offset: offset ? (offset - 1) * limit : 0,
              limit,
              filters: defaultFilters,
              sort: [
                {
                  field: "createdOn",
                  table: "R",
                  dir: "desc",
                },
              ],
            }
          : undefined,
      },
      action: "command",
      command: [
        {
          agent: "getRatingsList",
          appName: "selfMaximized",
          folder: "ratings",
        },
      ],
      excel: excelDownload ? true : undefined,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      requestBody
    );

    // Handle Excel download response if needed
    if (
      excelDownload &&
      response.data?.data?.data &&
      response.data.data.type === "Buffer"
    ) {
      // Convert the Buffer array back to binary data
      const bufferData = response.data.data.data;
      const uint8Array = new Uint8Array(bufferData);
      const blob = new Blob([uint8Array], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `ratings_report_${new Date().toISOString().split("T")[0]}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        link.remove();
      }, 100);

      return { success: true };
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching ratings list:", error);
    throw error;
  }
};

/**
 * Update the approval status of a rating
 */
export const updateRatingStatus = async ({
  id,
  isApproved,
}: UpdateRatingParams) => {
  try {
    const requestBody = {
      id,
      isApproved,
      action: "command",
      command: [
        {
          agent: "updateRatingStatusByAdmin",
          appName: "selfMaximized",
          folder: "ratings",
        },
      ],
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      requestBody
    );

    return response.data;
  } catch (error) {
    console.error("Error updating rating status:", error);
    throw error;
  }
};

/**
 * Get a specific doctor's rating details
 */
export const getDoctorRating = async ({ doctorId }: GetDoctorRatingParams) => {
  try {
    const requestBody = {
      doctorId,
      action: "command",
      command: [
        {
          agent: "getDoctorRating",
          appName: "selfMaximized",
          folder: "ratings",
        },
      ],
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      requestBody
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching doctor rating:", error);
    throw error;
  }
};

// Export all functions
export default {
  createRating,
  getRatingsList,
  updateRatingStatus,
  getDoctorRating,
};
