import axios from "axios";
import { useEffect, useState } from "react";

const usePuckEditorByPageName = (pageName: any) => {
  const [puckData, setPuckData] = useState<any>();
  const [puckloader, setPuckLoader] = useState<boolean>(false);
  useEffect(() => {
    getPuckDataByPageName();
  }, []);
  const getPuckDataByPageName = async () => {
    try {
      if (Array.isArray(pageName)) {
        setPuckLoader(true);

        const results = await Promise.all(
          pageName.map((page) =>
            axios.post(`${process.env.REACT_APP_API}/pipeline`, {
              name: page,
              action: "command",
              menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
              command: [
                {
                  agent: "pageByName",
                  appName: "selfMaximized",
                  folder: "pages",
                },
              ],
            })
          )
        );

        const formattedData = results.map((result: any) => {
          if (result?.data?.statusCode === 200) {
            return JSON.parse(result?.data?.data?.response?.[0]?.data || "{}");
          }
          return null;
        });

        setPuckData(formattedData);
        setPuckLoader(false);
      } else {
        setPuckLoader(true);
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            name: pageName,
            action: "command",
            menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
            command: [
              {
                agent: "pageByName",
                appName: "selfMaximized",
                folder: "pages",
              },
            ],
          }
        );

        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setPuckData(data);
          setPuckLoader(false);
        } else {
          setPuckData([]);
          setPuckLoader(false);
        }
      }
    } catch (err) {
      console.error("API Error:", err);
      setPuckData([]);
      setPuckLoader(false);
    } finally {
      setPuckLoader(false);
    }
  };

  return { puckData, puckloader };
};

export default usePuckEditorByPageName;
