import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import ShimmerUI from "./Shimmer";
import usePuckEditorByPageName from "../customHooks/usePuckEditorByPageName";

const About: React.FC = () => {
  const { puckData, puckloader } = usePuckEditorByPageName("ABOUT US");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const block1 = puckData?.content?.find((elem: any) => elem.type === "Block1");
  const block2 = puckData?.content?.find((elem: any) => elem.type === "Block2");
  const block3 = puckData?.content?.find((elem: any) => elem.type === "Block3");
  const block4 = puckData?.content?.find((elem: any) => elem.type === "Block4");
  const block5 = puckData?.content?.find((elem: any) => elem.type === "Block5");
  const block6 = puckData?.content?.find((elem: any) => elem.type === "Block6");
  const block7 = puckData?.content?.find((elem: any) => elem.type === "Block7");
  const block8 = puckData?.content?.find((elem: any) => elem.type === "Block8");

  return puckloader ? (
    <ShimmerUI />
  ) : (
    <>
      <section className="pb-0">
        <div className="container-fluid px-0">
          <div className="col-lg-12 pt-0">
            {block1 ? (
              <img
                src={
                  block1?.props?.bannerImage ??
                  "https://images.unsplash.com/photo-1477332552946-cfb384aeaf1c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                }
                style={{
                  height: "70vh",
                  width: "100%",
                  marginTop: "-20px",
                  objectFit: "cover",
                }}
                alt="Banner"
              />
            ) : null}
          </div>
        </div>
      </section>
      <section className="py-5 mt-5">
        <div className="container-fluid left-right-space">
          <div className="row">
            <div className="col-lg-5 pb-4 pb-lg-0">
              <h1 className="text-white letter_spacing text-uppercase text-center text-lg-start reviving-health ps-lg-7">
                {block2?.props?.bannerHeading ? (
                  block2?.props?.bannerHeading
                    .split(" ")
                    .map((word: any, index: any) => (
                      <span className="d-block" key={index}>
                        {word}
                      </span>
                    ))
                ) : (
                  <>
                    <span className="d-block">UNDER</span>
                    <span className="d-block">TESTING</span>
                    <span className="d-block">PHASE</span>
                  </>
                )}
              </h1>
            </div>
            <div className="col-lg-7">
              <p
                className="text-uppercase letter_spacing-one mb-0 para_text-about"
                style={{
                  color: "#ADADAD",
                  textTransform: "capitalize",
                }}
              >
                {block2?.props?.bannerDescription
                  ? block2?.props?.bannerDescription
                  : ` We're rebuilding health from the inside out. With the modern
                  environment evolving and everyday chemicals, pollutants,
                  pathogens, and processed ingredients threatening our mental
                  and physical state, our bodies need protection now more than
                  ever. At ARMRA, we use proprietary, Cold-Chain BioPotent™
                  Technology, and leverage immune intelligence to protect,
                  restore, and enhance complete immune health and cellular
                  performance. Harnessing the power of colostrum and its 400+
                  bioactive nutrients, we bring together technology and
                  superfood science, empowering you to face the modern world
                  head on. We're ARMRA. The revival of health.`}
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr className="text-white" />
      <section className="py-5">
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 ps-2 ps-lg-5 pe-0 pe-lg-4">
              <div className="ps-2 ">
                <h5
                  className="letter_spacing pb-3 text-uppercase"
                  style={{
                    fontWeight: 100,
                    color: "#C4C4C4",
                  }}
                >
                  {block3?.props?.foundationTitle
                    ? block3?.props?.foundationTitle
                    : `THE FOUNDATION`}
                </h5>
                <h2
                  className="text-uppercase"
                  style={{
                    color: "#FFFFFF",
                    letterSpacing: "1.7px",
                    wordSpacing: "2px",
                  }}
                >
                  {block3?.props?.foundationQuote
                    ? block3?.props?.foundationQuote
                    : ` "I created ARMRA to heal myself. It was developed with the
                  utmost intention, integrity, and respect for evolutionary
                  wisdom because I put this into my own body every single day.
                  Nature crafted by us, for us."`}
                </h2>
                <p
                  className="letter_spacing-one text-uppercase para_text-about mb-0"
                  style={{
                    color: "#ADADAD",
                    textTransform: "capitalize",
                  }}
                >
                  {block3?.props?.foundationDescription
                    ? block3?.props?.foundationDescription
                    : ` ARMRA is the brainchild of Sarah Rahal, MD - a double
                  board-certified pediatric neurologist with expertise in
                  environmental health and quantum biology. Dr. Rahal is
                  constant seeker of knowledge, research, and what lies beyond
                  the status quo. `}
                </p>
                <p
                  className="letter_spacing-one pt-4 text-uppercase para_text-about mb-0"
                  style={{
                    color: "#ADADAD",
                    textTransform: "capitalize",
                  }}
                >
                  {block3?.props?.foundationDescription2
                    ? block3?.props?.foundationDescription2
                    : ` Galvanized by a catastrophic health crisis that almost took
                  her life, Dr. Rahal forged a path down R&D to develop ARMRA
                  Colostrum's proprietary technology and save herself. She
                  spearheaded the colostrum category, launching ARMRA in 2020
                  with a mission to share access to this simple tool that holds
                  the power to radically transform health for anyone, and
                  advance flourishing at scale.`}
                </p>
              </div>
            </div>
            <div className="col-lg-6 pt-5 pt-lg-0 pe-2 pe-lg-0">
              <div className="d-flex justify-content-lg-end ">
                <img
                  className="img-fluid w-100 object-cover"
                  src={
                    block3?.props?.foundationImage
                      ? block3?.props?.foundationImage
                      : "https://images.unsplash.com/photo-1501747315-124a0eaca060?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  }
                  height={930}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 pb-3 pb-lg-0 px-2 px-lg-0">
              <div className="fourth-box_left_box">
                <img
                  className="img-fluid w-100"
                  src={
                    block4?.props?.foundationImage2
                      ? block4?.props?.foundationImage2
                      : "https://images.unsplash.com/photo-1506126613408-eca07ce68773?q=80&w=1999&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  }
                  height={500}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 pe-3 pe-md-6">
              <div
                className="letter_spacing-one text-uppercase p-0 p-lg-4"
                style={{
                  color: "#ADADAD",
                  fontSize: "18px",
                }}
              >
                <h5
                  className="letter_spacing pb-3"
                  style={{
                    fontWeight: 100,
                    color: "#C4C4C4",
                  }}
                >
                  {block4?.props?.foundationTitle2
                    ? block4?.props?.foundationTitle2
                    : `THE FOUNDATION`}
                </h5>
                <p className="mb-0 third-block-text">
                  {block4?.props?.foundationDescription2 ??
                    ` Her foundational approach to medicine anchors her to the
                  importance of honoring the evolutionary wisdom of the body,
                  the unrivaled power of the body to repair itself, and the
                  urgency of equipping the body with the right nutrients to do
                  so.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="row">
          <div
            id="carouselExampleControls"
            className="carousel slide h-100 overflow-hidden"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              {block5?.props?.carousalItems ? (
                block5?.props?.carousalItems.map((item: any, index: any) => {
                  return (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      style={{ height: "300px" }}
                      key={index}
                    >
                      <img
                        src={item.image}
                        className="d-block w-100 h-100"
                        alt="..."
                        style={{
                          opacity: "0.6",
                          objectFit: "cover",
                          objectPosition: "center",
                          backgroundImage: "cover",
                        }}
                      />
                      <div className="carousel-caption ">
                        <h2
                          style={{
                            letterSpacing: "2px",
                            fontWeight: "100",
                          }}
                          className="mb-3"
                        >
                          {item.title}
                        </h2>
                        <Rating
                          readonly={true}
                          initialValue={item.rating}
                          size={30}
                          className="stat-rating mb-3"
                          fillColor="white"
                          emptyColor="black"
                          SVGstorkeWidth="1px"
                          SVGstrokeColor="white"
                          allowFraction
                        />
                        <br />
                        <p
                          className="text-uppercase"
                          style={{
                            letterSpacing: "3px",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontWeight: "50",
                            fontSize: "18px",
                          }}
                        >
                          {item.description}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  className="carousel-item active"
                  style={{ height: "300px" }}
                >
                  <img
                    src="https://images.unsplash.com/photo-1721893484306-23d5379b5d9f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyNXx8fGVufDB8fHx8fA%3D%3D"
                    className="d-block w-100 h-100"
                    alt="..."
                    style={{ opacity: "0.7" }}
                  />
                  <div className="carousel-caption ">
                    <h2
                      style={{
                        letterSpacing: "1px",
                        fontWeight: "100",
                      }}
                      className="mb-3"
                    >
                      TRUSTED BY
                    </h2>
                    <Rating
                      readonly={true}
                      initialValue={4}
                      size={30}
                      className="mb-3"
                    />
                    <br />
                    <p
                      className="text-uppercase"
                      style={{
                        letterSpacing: "3px",
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontWeight: "50",
                        fontSize: "18px",
                      }}
                    >
                      Contrary To Popular Belief, Lorem Ipsum Is Not Simply
                      Random Text. It Has Roots In A Piece Of Classical Latin
                      Literature From 45 BC, Making It Over 2000 Years Old.
                      Richard Mcclintock, A Latin Professor At Hampden-Sydney
                      College In Virginia, Looked Up One Of The More Obscure
                      Latin Words
                    </p>
                  </div>
                </div>
              )}
            </div>
            <button
              className="carousel-control-prev "
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon "
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container-fluid left-right-space">
          <div className="row">
            <div className="col-lg-12">
              <h2
                style={{
                  letterSpacing: "4px",
                  fontWeight: "100",
                }}
                className="pb-5 grid-heading_text text-center text-md-start text-white mb-0"
              >
                {block6?.props?.heading ?? "HEADING"}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 pb-3">
              <h5 className="text-white letter_spacing-one mb-3 text-uppercase">
                {block6?.props?.entreprenaurJourney1
                  ? block6?.props?.entreprenaurJourney1
                  : ` MY
                  ENTREPRENEURIAL JOURNEY`}
              </h5>
              <p
                className="letter_spacing text-uppercase para_text-about mb-0"
                style={{
                  color: "#ADADAD",
                  letterSpacing: "3px",
                  textTransform: "capitalize",
                }}
              >
                {block6?.props?.entreprenaurJourneyDescription1
                  ? block6?.props?.entreprenaurJourneyDescription1
                  : `We're rebuilding health from the inside out. With the modern
                environment evolving and everyday chemicals, pollutants,
                pathogens, and processed ingredients threatening our mental and
                physical state, our bodies need protection now more than ever.
                At ARMRA, we use proprietary, Cold-Chain BioPotent™ Technology,
                and leverage immune intelligence to protect, restore, and
                enhance complete immune health and cellular performance.
                Harnessing the power of colostrum and its 400+ bioactive
                nutrients, we bring together technology and superfood science,
                empowering you to face the modern world head on. We're ARMRA.
                The revival of health.`}
              </p>
            </div>
            <div className="col-lg-6 pb-3">
              <h5 className="text-white letter_spacing-one mb-3 text-uppercase ">
                {block6?.props?.entreprenaurJourney2
                  ? block6?.props?.entreprenaurJourney2
                  : ` MY
                  ENTREPRENEURIAL JOURNEY`}
              </h5>
              <p
                className="letter_spacing text-uppercase para_text-about mb-0"
                style={{
                  color: "#ADADAD",
                  letterSpacing: "3px",
                  textTransform: "capitalize",
                }}
              >
                {block6?.props?.entreprenaurJourneyDescription2
                  ? block6?.props?.entreprenaurJourneyDescription2
                  : `We're rebuilding health from the inside out. With the modern
                environment evolving and everyday chemicals, pollutants,
                pathogens, and processed ingredients threatening our mental and
                physical state, our bodies need protection now more than ever.
                At ARMRA, we use proprietary, Cold-Chain BioPotent™ Technology,
                and leverage immune intelligence to protect, restore, and
                enhance complete immune health and cellular performance.
                Harnessing the power of colostrum and its 400+ bioactive
                nutrients, we bring together technology and superfood science,
                empowering you to face the modern world head on. We're ARMRA.
                The revival of health.`}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 pb-5">
        <div className="container-fluid left-right-space">
          <div className="row">
            <div className="col-lg-6 pb-3 pb-lg-0">
              <h5 className="text-white letter_spacing-one mb-3 text-uppercase">
                {block6?.props?.entreprenaurJourney3
                  ? block6?.props?.entreprenaurJourney3
                  : ` MY
                  ENTREPRENEURIAL JOURNEY`}
              </h5>
              <p
                className="letter_spacing text-uppercase para_text-about mb-0"
                style={{
                  color: "#ADADAD",
                  letterSpacing: "3px",
                  textTransform: "capitalize",
                }}
              >
                {block6?.props?.entreprenaurJourneyDescription3
                  ? block6?.props?.entreprenaurJourneyDescription3
                  : `We're rebuilding health from the inside out. With the modern
                environment evolving and everyday chemicals, pollutants,
                pathogens, and processed ingredients threatening our mental and
                physical state, our bodies need protection now more than ever.
                At ARMRA, we use proprietary, Cold-Chain BioPotent™ Technology,
                and leverage immune intelligence to protect, restore, and
                enhance complete immune health and cellular performance.
                Harnessing the power of colostrum and its 400+ bioactive
                nutrients, we bring together technology and superfood science,
                empowering you to face the modern world head on. We're ARMRA.
                The revival of health.`}
              </p>
            </div>
            <div className="col-lg-6">
              <div className="ps-0 ps-lg-2">
                <h5 className="text-white letter_spacing-one mb-3 text-uppercase ">
                  {block6?.props?.entreprenaurJourney4
                    ? block6?.props?.entreprenaurJourney4
                    : ` MY
                  ENTREPRENEURIAL JOURNEY`}
                </h5>
                <p
                  className="letter_spacing-one text-uppercase mb-0 para_text-about"
                  style={{
                    color: "#ADADAD",
                    letterSpacing: "3px",
                    textTransform: "capitalize",
                  }}
                >
                  {block6?.props?.entreprenaurJourneyDescription4
                    ? block6?.props?.entreprenaurJourneyDescription4
                    : `We're rebuilding health from the inside out. With the modern
                environment evolving and everyday chemicals, pollutants,
                pathogens, and processed ingredients threatening our mental and
                physical state, our bodies need protection now more than ever.
                At ARMRA, we use proprietary, Cold-Chain BioPotent™ Technology,
                and leverage immune intelligence to protect, restore, and
                enhance complete immune health and cellular performance.
                Harnessing the power of colostrum and its 400+ bioactive
                nutrients, we bring together technology and superfood science,
                empowering you to face the modern world head on. We're ARMRA.
                The revival of health.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="row">
          <div
            id="carouselExampleControls1"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              {block7?.props?.carousalItemsUsers ? (
                block7?.props?.carousalItemsUsers.map(
                  (item: any, index: any) => {
                    return (
                      <div
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                        style={{ height: "300px" }}
                        key={index}
                      >
                        <img
                          src={item.image}
                          className="d-block w-100 h-100"
                          alt="..."
                          style={{
                            opacity: "0.6",
                            objectFit: "cover",
                            objectPosition: "center",
                            backgroundImage: "cover",
                          }}
                        />
                        <div className="carousel-caption ">
                          <h2
                            style={{
                              letterSpacing: "2px",
                              fontWeight: "100",
                            }}
                            className="mb-3"
                          >
                            {item.title}
                          </h2>
                          <Rating
                            readonly={true}
                            initialValue={item.rating}
                            size={30}
                            className="mb-3 stat-rating"
                            fillColor="white"
                            emptyColor="black"
                            SVGstorkeWidth="1px"
                            SVGstrokeColor="white"
                            allowFraction
                          />
                          <br />
                          <p
                            className="text-uppercase"
                            style={{
                              letterSpacing: "3px",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontWeight: "50",
                              fontSize: "18px",
                            }}
                          >
                            {item.description}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div
                  className="carousel-item active"
                  style={{ height: "300px" }}
                >
                  <img
                    src="https://images.unsplash.com/photo-1721893484306-23d5379b5d9f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyNXx8fGVufDB8fHx8fA%3D%3D"
                    className="d-block w-100 h-100"
                    alt="..."
                    style={{ opacity: "0.7" }}
                  />
                  <div className="carousel-caption ">
                    <h2
                      style={{
                        letterSpacing: "1px",
                        fontWeight: "100",
                      }}
                      className="mb-3"
                    >
                      TRUSTED BY
                    </h2>
                    <Rating
                      readonly={true}
                      initialValue={4}
                      size={30}
                      className="mb-3"
                    />
                    <br />
                    <p
                      className="text-uppercase"
                      style={{
                        letterSpacing: "3px",
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        fontWeight: "50",
                        fontSize: "18px",
                      }}
                    >
                      Contrary To Popular Belief, Lorem Ipsum Is Not Simply
                      Random Text. It Has Roots In A Piece Of Classical Latin
                      Literature From 45 BC, Making It Over 2000 Years Old.
                      Richard Mcclintock, A Latin Professor At Hampden-Sydney
                      College In Virginia, Looked Up One Of The More Obscure
                      Latin Words
                    </p>
                  </div>
                </div>
              )}
            </div>
            <button
              className="carousel-control-prev "
              type="button"
              data-bs-target="#carouselExampleControls1"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon "
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls1"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-0">
        <div className="container-fluid g-lg-0">
          <div className="row d-flex align-items-center">
            <div className="col-lg-7 pb-4 pb-lg-0">
              <div className="d-flex justify-content-center justify-content-lg-start reviving-foundation_left">
                <img
                  className="img-fluid w-100 object-cover"
                  src={
                    block8?.props?.footerImage
                      ? block8?.props?.footerImage
                      : "https://images.unsplash.com/photo-1506126613408-eca07ce68773?q=80&w=1999&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  }
                  height={500}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-6 pe-3 pe-md-6">
              <div className="p-0 p-lg-3">
                <h2
                  className="text-white text-uppercase mb-0"
                  style={{
                    letterSpacing: "3px",
                  }}
                >
                  {block8?.props?.footerHeading
                    ? block8?.props?.footerHeading
                    : "REVIVING FOUNDATIONAL"}
                </h2>
                <div
                  className="letter_spacing-one pt-4 text-uppercase"
                  style={{ color: "#ADADAD", textTransform: "capitalize" }}
                >
                  {block8?.props?.footerDescription ? (
                    block8?.props?.footerDescription
                  ) : (
                    <p className="mb-0 para_text-about">
                      {`Her foundational approach to medicine anchors her to the
                  importance of honoring the evolutionary wisdom of the body,
                  the unrivaled power of the body to repair itself, and the
                  urgency of equipping the body with the right nutrients to do
                  so. Dr. Rahal trained at Columbia Presbyterian Medical Center
                  in New York. Prior to founding ARMRA Colostrum, she was
                  Assistant Professor of Neurology and Pediatrics, and the
                  Director of Pediatric Headache Medicine, at the Icahn School
                  of Medicine at Mount Sinai Hospital in NY.`}
                    </p>
                  )}
                </div>
                <Link
                  to={"/categories"}
                  className="btn mt-4 get-started-btn"
                  style={{
                    backgroundColor: `${
                      block8?.props?.getStartedBackgroundColor
                        ? block8?.props?.getStartedBackgroundColor
                        : "#FFFFFF"
                    }`,
                    padding: "3px 22px",
                    fontSize: "16px",
                    letterSpacing: "3px",
                    cursor: "pointer",
                    borderRadius: "0px",
                    // lineHeight: "25px",
                    color: `${
                      block8?.props?.getStartedColor
                        ? block8?.props?.getStartedColor
                        : "black"
                    }`,
                  }}
                >
                  {block8?.props?.getStartedText
                    ? block8?.props?.getStartedText
                    : "GET STARTED"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
