import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import Select from "react-select";
import { format, startOfWeek, endOfMonth } from "date-fns";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import BookSubscription from "./Subscription/BookSubscription";
import WriteReview from "./Review/WriteReview";
import ViewReview from "./Review/ViewReview";
import { Modal } from "bootstrap";
import BookedAppointment from "./Subscription/BookedAppointment";
import ViewSelectedSlots from "./Subscription/ViewSelectedSlots";
import SpecialistInfo from "./SpecialistInfo";
import usePuckEditorByPageName from "../customHooks/usePuckEditorByPageName";
import { createRating, getDoctorRating } from "../APIService/RatingAPI";

interface BookSpecialistUuid {
  doctorUuid: any;
  result: any;
}

interface Slot {
  uuid: string;
  calendarDate: string;
  doctorId: number;
  StartTime: string;
  EndTime: string;
  Duration: string;
  calendarDay: string;
  isBooked: number;
  dname: string;
}

interface GroupedSlots {
  [day: string]: Slot[];
}
interface DoctorRatingData {
  average_rating: string;
  doctorId: number;
  doctor_total_rating_count: number;
  total_rating_count: number;
}

interface DoctorReview {
  createdOn: string;
  id: number;
  isApproved: number;
  rating: string;
  review: string;
  specialistName: string;
  userName: string;
}

interface RatingPercentage {
  rating: string;
  rating_count: number;
  rating_percentage: string;
}

interface RatingResponse {
  doctorRating: DoctorRatingData[];
  doctorReviews: DoctorReview[];
  ratingWisePercentageAndCounts: RatingPercentage[];
}
const BookSpecialist: React.FC<BookSpecialistUuid> = ({
  doctorUuid,
  result,
}) => {
  const { id } = useParams();
  const [productData, setProductData] = useState<any>([]);
  const [specialistData, setSpecialistData] = useState<any>([]);
  const [productDuration, setProductDuration] = useState<any>("");
  const [slots, setSlots] = useState<any>([]);
  const [selectedSlot, setSelectedSlot] = useState<any>("");
  const [ipAddress, setIpAddress] = useState<any>("");
  const [cartId, setCartId] = useState<any>("");
  const [productId, setProductUuid] = useState<any>("");
  const [productKeyId, setProductId] = useState<any>("");
  const [subscriptionProductId, setSubscriptionProductId] = useState<any>(0);
  const today = new Date();
  const [startDate, setStartDate] = useState<any>(startOfWeek(today));
  const [endDate, setEndDate] = useState<any>(endOfMonth(today));
  const [rangeSelected, setRangeSelected] = useState<any>(false);
  const [dateRange, setSelectedRange] = useState<any>("");
  const [selectedMonth, setSelectedMonth] = useState<any>(
    format(today, "MMMM")
  );
  const [modeType, setModeType] = useState<any>("");
  const [isChecked, setIsChecked] = useState<any>(false);
  const [bookStatus, setBookStatus] = useState<any>("bookNow");
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [timeZones, setTimeZones] = useState<any>([]);
  const [selectedTimezone, setSelectedTimeZone] = useState<any>("");
  const [selectedProduct, setSelectedProduct] = useState<boolean>(false);
  const [selectedMode, setSelectedMode] = useState<boolean>(false);
  const [ratingData, setRatingData] = useState<RatingResponse | null>(null);
  // Function to receive selected data from BookSubscription
  const [selectedProductUuid, setSelectedProductUuid] = useState<string>("");
  const [selectedModeType, setSelectedModeType] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  const [isSubscriptionChecked, setIsSubscriptionChecked] =
    useState<boolean>(false);
  const [consumerChoice, setConsumerChoice] = useState<string>("");
  const [undoAllSub, setUndoAllSub] = useState<boolean>(false);
  const [subDuration, setSubDuration] = useState<number>(0);
  const [nextProductBookedType, setNextProductBookedType] = useState<any>("");
  const [selectedAppointments, setSelectedAppointments] = useState<any>([]);
  const [selectedBookedType, setSelectedBookedType] = useState<any>({});
  const [alertUser, setAlertUser] = useState<boolean>(false);
  const [stripePriceId, setStripePriceId] = useState<string>("");
  const [categoryUuid, setCategoryUuid] = useState<string>("");
  const [singularWarningStatus, setSingularWarningStatus] =
    useState<boolean>(false);
  const [warningStatus, setWarningStatus] = useState<boolean>(false);
  const specialistDetails = useRef<any>("");
  const [navigatingToStripe, setNavigatingToStripe] = useState<boolean>(false);
  const location = useLocation();
  const categoryID = location.pathname.split("/")[2];

  //Ratinf usestates
  const [reviewData, setReviewData] = useState({
    rating: 0,
    reviewText: "",
  });
  const { puckData, puckloader } = usePuckEditorByPageName([
    "BOOK SLOTPOPUP",
    "BOOK SPECIALIST",
  ]);
  const bookSlotPopUpData: any =
    puckData &&
    puckData[0]?.content?.find((elem: any) => elem.type === "BookSlotPopUp");

  const bookSpecialistData: any =
    puckData &&
    puckData[1]?.content?.find((elem: any) => elem.type === "BookSpecialist");

  useEffect(() => {
    if (!selectedProduct && !modeType) {
      setSingularWarningStatus(false);
    }
  }, [selectedProduct, modeType]);

  useEffect(() => {
    if (isSubscriptionChecked) {
      setSelectedProduct(false);
      setSelectedMode(false);
      setIsChecked(false);
      setModeType("");
      setProductId("");
      setProductUuid("");
      setConsumerChoice("subscription");
      setBookStatus("");
    }
  }, [isSubscriptionChecked]);

  useEffect(() => {
    if (isChecked || !isChecked) {
      setSelectedBookedType({});
    }
  }, [isChecked]);

  useEffect(() => {
    if (selectedProduct || selectedMode || isChecked) {
      setUndoAllSub(true);
      setConsumerChoice("singular");
      setBookStatus("bookNow");
    }
  }, [selectedProduct, selectedMode, isChecked]);

  useEffect(() => {
    if (selectedProductUuid || selectedModeType || selectedPlan) {
      setSelectedProduct(false);
      setSelectedMode(false);
      setIsChecked(false);
      setModeType("");
      setConsumerChoice("subscription");
      setBookStatus("");
    }
  }, [selectedProductUuid, selectedModeType, selectedPlan]);

  useEffect(() => {
    getGlobalTimeZones();
  }, []);

  useEffect(() => {
    loadDoctorRating();
  }, [specialistData]);
  useEffect(() => {
    if (!selectedProduct) {
      setProductUuid("");
    }
  }, [!selectedProduct]);

  useEffect(() => {
    if (!selectedMode) {
      setModeType("");
    }
  }, [!selectedMode]);

  useEffect(() => {
    const handlePopState = () => {
      const dismissModal = document.getElementById("dismissModal");
      if (dismissModal) {
        dismissModal.click();
      }
      const dismissFirstModal = document.getElementById("closeFirstModal");
      if (dismissFirstModal) {
        dismissFirstModal.click();
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (doctorUuid.length === 1 && id) {
      const openModal = () => {
        let modal: any = document.getElementById("popUpModal");
        if (modal) {
          modal.click();
          window.history.pushState(null, "", window.location.href);
        }
      };
      openModal();
      setSelectedModeType("");
      setSelectedMode(false);
      setIsChecked(false);
      setProductUuid("");
      setProductId("");
      setSelectedProduct(false);
      setSelectedProductUuid("");
      setSelectedPlan(0);
      setIsSubscriptionChecked(false);
      categoryWiseSpecialistProducts();
      getSpecialistDetailsById();
    }
  }, [doctorUuid, id]);

  useEffect(() => {
    setSlots([]);
    if (dateRange) {
      setRangeSelected(true);
    }
    if (Array.isArray(dateRange) && dateRange.length === 2) {
      const [startDate, endDate] = dateRange;
      setStartDate(startDate);
      setEndDate(endDate);
      setTimeout(() => {
        getSpecialistBookingSlots(startDate, endDate);
      }, 1000);
    } else if (startDate || endDate) {
      getSpecialistBookingSlots(startDate, endDate);
    }
  }, [selectedTimezone?.value]);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIpAddress(data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  const handleBookedType = useCallback(
    (type: any) => {
      setSelectedBookedType(type);
    },
    [selectedBookedType]
  );

  const handlecheckSelectedBookedType = useCallback(
    (type: any) => {
      setAlertUser(type);
    },
    [alertUser]
  );

  const handleSelectedData = useCallback(
    (data: {
      productUuid: string;
      modeType: string;
      planSelected: number;
      isChecked: boolean;
      type: string;
      subDuration: number;
      stripePriceId: string;
      subProductId: number;
      categoryUuid: string;
    }) => {
      setSelectedProductUuid(data.productUuid);
      setSelectedModeType(data.modeType);
      setSelectedPlan(data.planSelected);
      setIsSubscriptionChecked(data.isChecked);
      setConsumerChoice(data.type);
      setSubDuration(data.subDuration);
      setStripePriceId(data.stripePriceId);
      setSubscriptionProductId(data.subProductId);
      setCategoryUuid(data.categoryUuid);
    },
    [
      selectedProductUuid,
      selectedModeType,
      selectedPlan,
      isSubscriptionChecked,
      consumerChoice,
      subDuration,
      stripePriceId,
      subscriptionProductId,
      categoryUuid,
    ]
  );

  const categoryId = location.pathname.split("/");
  let data = localStorage.getItem("userInfo");
  let userInfo = null;
  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }

  let lastActiveCartId: any = userInfo ? userInfo.lastActiveCart : null;

  const handleReviewModal = () => {
    let reviewModal: any = document.getElementById("reviewModal");
    if (reviewModal) {
      reviewModal.click();
    }
  };
  const handleReviewSubmit = async (data: {
    rating: number;
    reviewText: string;
  }) => {
    let userInfo: any = localStorage.getItem("userInfo");

    try {
      const response = await createRating({
        doctorId: specialistData?.id,
        userId: userInfo?.id,
        rating: reviewData?.rating,
        review: reviewData?.reviewText,
      });
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };
  const getUserRating = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        doctorId: specialistData.id,
        categoryId: categoryId[2],
        action: "command",
        command: [
          {
            agent: "userStarPercentageList",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
        }
      });
  };

  const handleReviewModal2 = async () => {
    // await getUserRating();
    let reviewModal2: any = document.getElementById("reviewModal2");
    if (reviewModal2) {
      reviewModal2.click();
    }
  };

  const handlBookSlotModal = async () => {
    if (!productKeyId || !modeType) {
      return setSingularWarningStatus(true);
    }
    if (isChecked) {
      setConsumerChoice("singular");
    }
    if (isSubscriptionChecked) {
      setConsumerChoice("subscription");
    }

    let bookSlotModal: any = document.getElementById("bookSlotModal");
    if (bookSlotModal) {
      bookSlotModal.click();
      window.history.pushState(null, "", window.location.href);
      let currentTz: any = "";

      const getTimeZoneFromStorage = localStorage.getItem(
        "userSelectedTimezone"
      );
      if (!getTimeZoneFromStorage) {
        const response = await axios.get("https://ipapi.co/json/");
        if (response?.data && response?.data?.timezone) {
          currentTz = {
            value: response?.data?.timezone,
            label: response?.data?.timezone,
          };
        }
        setSelectedTimeZone(currentTz);
        localStorage.setItem("userSelectedTimezone", JSON.stringify(currentTz));
      } else {
        setSelectedTimeZone(
          getTimeZoneFromStorage && JSON.parse(getTimeZoneFromStorage)
        );
      }

      await getSpecialistBookingSlots(startDate, endDate);
    }
  };

  async function categoryWiseSpecialistProducts() {
    if (doctorUuid.length && id) {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            doctorId: doctorUuid.length !== 0 ? doctorUuid[0] : 0,
            categoryUuid: id,
            isActive: 1,
            action: "command",
            command: [
              {
                agent: "productBySpecCategory",
                appName: "selfMaximized",
                folder: "specialist",
              },
            ],
          }
        );
        if (result?.data?.statusCode === 200) {
          setProductData(result?.data?.data);
        } else {
          setProductData([]);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function getSpecialistDetailsById() {
    try {
      const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 1,
            filters: [
              {
                field: "id",
                operator: "eq",
                value: doctorUuid,
              },
            ],
            sort: [
              {
                field: "id",
                table: "Users",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "specialistListsData",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      });

      if (result?.data?.statusCode === 200) {
        setSpecialistData(result?.data?.data[0]);
      } else {
        setSpecialistData([]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const selectProduct = (appointmentDurationType: any) => {
    if (appointmentDurationType?.duration !== productDuration) {
      setSelectedProduct(true);
    } else {
      setSelectedProduct(!selectedProduct);
    }
    setProductDuration(appointmentDurationType.duration);
    setProductUuid(appointmentDurationType.uuid);
    setProductId(appointmentDurationType.id);
  };

  const handleRangeSelection = async (selectedRange: any) => {
    if (selectedRange) {
      setRangeSelected(true);
      setSelectedRange(selectedRange);
    }
    if (Array.isArray(selectedRange) && selectedRange.length === 2) {
      const [startDate, endDate] = selectedRange;
      setStartDate(startDate);
      setEndDate(endDate);
      await getSpecialistBookingSlots(startDate, endDate);
    } else {
      console.error("Invalid date range selected");
    }
  };

  const getSpecialistBookingSlots = async (
    newStartDate?: any,
    newEndDate?: any
  ) => {
    try {
      // Ensure the selected timezone is being used, fallback to system timezone if not available
      const userTimezonee = selectedTimezone?.value || moment.tz.guess();

      // Format start and end dates to the correct timezone
      const formattedStartDate = moment
        .tz(newStartDate || startDate, userTimezonee)
        .format("YYYY-MM-DD");
      const formattedEndDate = moment
        .tz(newEndDate || endDate, userTimezonee)
        .format("YYYY-MM-DD");

      const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: formattedStartDate,
                opr: "AND",
                value2: formattedEndDate,
              },
              {
                field: "doctorId",
                operator: "eq",
                table: "BookingCalendar",
                value: specialistData?.id,
              },
              {
                field: "isBooked",
                operator: "eq",
                table: "BookingCalendar",
                value: 0,
              },
              {
                field: "active",
                operator: "eq",
                table: "BookingCalendar",
                value: 1,
              },
              {
                field: "Duration",
                operator: "eq",
                table: "BookingCalendar",
                value: parseFloat(
                  subDuration ? subDuration : productDuration
                ).toFixed(2),
              },
            ],
            sort: [
              {
                field: "calendarDate",
                table: "BookingCalendar",
                dir: "asc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "specialistCalendarBooking",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      });

      if (result?.data?.code === 1) {
        const responseData: Slot[] = result?.data?.data?.response || [];

        // Get the current date and time in the selected timezone
        const now = moment.tz(selectedTimezone?.value || userTimezone);

        // Helper function to preprocess StartTime and convert to localized datetime
        const getSlotDateTime = (startTime: string): moment.Moment => {
          return moment
            .tz(
              startTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
              "YYYY-MM-DDTHH:mm:ssZ", // Parse format
              "UTC"
            )
            .tz(selectedTimezone?.value || userTimezone);
        };

        // Filter out past dates and times
        const filteredSlots = responseData.filter((slot) => {
          const slotDateTime = getSlotDateTime(slot.StartTime);

          // Include only slots in the future
          return slot.isBooked === 0 && slotDateTime.isAfter(now);
        });

        // Group slots by their localized calendarDate
        const groupedSlots: GroupedSlots = filteredSlots.reduce(
          (acc: GroupedSlots, slot: Slot) => {
            const slotDate = getSlotDateTime(slot.StartTime).format(
              "YYYY-MM-DD"
            );
            acc[slotDate] = [...(acc[slotDate] || []), slot];
            return acc;
          },
          {}
        );

        // Organize slots into days with their corresponding slots
        const filteredSlotsArray = Object.entries(groupedSlots)
          .map(([day, slots]: [string, Slot[]]) => ({
            dayOfWeek: moment(day, "YYYY-MM-DD")
              .tz(selectedTimezone?.value || userTimezone)
              .format("dddd"), // Convert to day of the week
            slots,
          }))
          .filter((daySlots) => daySlots.slots.length > 0); // Only include days with available slots

        setSlots(filteredSlotsArray);
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const passSlots = (selectedSlotData: any) => {
    consumerChoice === "subscription"
      ? setSelectedAppointments((prevState: any) => {
          const exists = prevState.some(
            (elem: any) =>
              elem?.StartTime === selectedSlotData?.StartTime &&
              elem?.EndTime === selectedSlotData?.EndTime &&
              elem?.calendarDate === selectedSlotData?.calendarDate
          );

          if (exists) {
            const updatedAppointments = prevState.filter(
              (elem: any) =>
                !(
                  elem?.StartTime === selectedSlotData?.StartTime &&
                  elem?.EndTime === selectedSlotData?.EndTime &&
                  elem?.calendarDate === selectedSlotData?.calendarDate
                )
            );

            setSelectedSlot(
              updatedAppointments.length > 0
                ? updatedAppointments[updatedAppointments.length - 1]
                : null
            );

            return updatedAppointments;
          } else {
            const bookingValues = Object.values(selectedBookedType);
            const bookNowCount = bookingValues.filter(
              (elem) => elem === 1
            ).length;
            if (prevState.length === bookNowCount) {
              const updatedAppointments = [
                ...prevState.slice(0, -1),
                selectedSlotData,
              ];
              setSelectedSlot(selectedSlotData);
              return updatedAppointments;
            } else {
              const updatedAppointments = [...prevState, selectedSlotData];
              setSelectedSlot(selectedSlotData);
              return updatedAppointments;
            }
          }
        })
      : setSelectedSlot(selectedSlotData);
  };

  const createCart = async () => {
    if (isSubscriptionChecked) {
      const modal = document.getElementById("appSelectedModalOpen");
      setConsumerChoice("subscription");
      if (modal) {
        setTimeout(() => {
          const modalInstance = new Modal(modal);
          modalInstance.show();
        }, 100);
        return;
      }
    } else {
      setConsumerChoice("singular");
    }
    let data = localStorage.getItem("userInfo");
    let userInfo: any = null;

    if (typeof data === "string") {
      try {
        userInfo = JSON.parse(data);
      } catch (error) {
        console.error("Error parsing userInfo:", error);
      }
    } else {
      userInfo = data;
    }
    let lastActiveCartId: any = userInfo ? userInfo.lastActiveCart : null;
    const cartId = localStorage.getItem("cartId");
    if (lastActiveCartId || cartId) {
      await addToCart();
    } else {
      if (!selectedSlot && bookStatus === "bookNow") {
        return toast.info("PLEASE SELECT SLOT FIRST", {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="60"
              height="60"
              viewBox="0 0 30 30"
            >
              <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
            </svg>
          ),
          className: "custom-toast",
          bodyClassName: "custom-toast",
        });
      }
      axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          ip: ipAddress,
          action: "command",
          command: [
            {
              agent: "createCart",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        })
        .then(async (result) => {
          if (result?.data?.statusCode === 200) {
            setCartId(result?.data?.data?.cartId);
            localStorage.setItem("cartId", result?.data?.data?.cartId);
            await addToCart();
          }
        });
    }
  };

  const addToCart = async () => {
    const cartId = localStorage.getItem("cartId");
    const cartListData = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        cartUuid:
          lastActiveCartId !== null && lastActiveCartId !== undefined
            ? lastActiveCartId
            : cartId,
        organizationId: 1,
        action: "command",
        command: [
          {
            agent: "cartList",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      }
    );

    const refinedCartList = cartListData?.data?.data;

    const dismissModal = document.getElementById("dismissModal");
    if (dismissModal) {
      dismissModal.click();
    }
    if (!selectedSlot && bookStatus === "bookNow") {
      return toast.info("PLEASE SELECT SLOT FIRST", {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="60"
            height="60"
            viewBox="0 0 30 30"
          >
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
          </svg>
        ),
        className: "custom-toast",
        bodyClassName: "custom-toast",
      });
    }

    // Check if the slot already exists in the cart and show a toast if it does
    const isSlotAlreadySelected =
      refinedCartList.length !== 0 &&
      refinedCartList.some((elem: any) => {
        if (
          elem.start_time === selectedSlot.StartTime &&
          elem.end_time === selectedSlot.EndTime &&
          elem.appointment_day === selectedSlot.calendarDay &&
          elem.appointment_date === selectedSlot.calendarDate
        ) {
          toast.info("THIS TIME SLOT FOR SELECTED DAY IS ALREADY SELECTED", {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 30 30"
              >
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
              </svg>
            ),
            className: "custom-toast",
            bodyClassName: "custom-toast",
          });
          return true;
        }
        return false;
      });

    if (!isSlotAlreadySelected) {
      // If the slot is not already selected, proceed to add to cart
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          cartUuid:
            lastActiveCartId !== null && lastActiveCartId !== undefined
              ? lastActiveCartId
              : cartId,
          productId: productId,
          ip: ipAddress,
          qty: 1,
          organizationId: 1,
          metadata: [
            {
              productId: productKeyId ? productKeyId : null,
              doctorId: specialistData ? specialistData.id : null,
              StartTime:
                bookStatus === "bookLater" ? null : selectedSlot.StartTime,
              EndTime: bookStatus === "bookLater" ? null : selectedSlot.EndTime,
              Duration: productDuration,
              Day: bookStatus === "bookLater" ? null : selectedSlot.calendarDay,
              Date:
                bookStatus === "bookLater" ? null : selectedSlot.calendarDate,
              categoryid: categoryID,
              modeType: modeType,
              timezone: selectedTimezone?.value,
              meta_type: "singular",
              bookStatus: bookStatus === "bookLater" ? 0 : 1,
              bookingSlotId:
                bookStatus === "bookLater" ? null : selectedSlot?.bookingSlotId,
            },
          ],
          appointment: [
            {
              appnt_type: "singular",
              chat_mode: modeType,
              doctorId: specialistData ? specialistData.id : null,
              book_status: bookStatus === "bookLater" ? 0 : 1,
              start_time:
                bookStatus === "bookLater" ? null : selectedSlot.StartTime,
              end_time:
                bookStatus === "bookLater" ? null : selectedSlot.EndTime,
              appointment_day:
                bookStatus === "bookLater" ? null : selectedSlot.calendarDay,
              appointment_date:
                bookStatus === "bookLater" ? null : selectedSlot.calendarDate,
              start_date: null,
              end_date: null,
              timezone: selectedTimezone?.value,
              admin_discount: 0,
              plan_per_month: 1,
            },
          ],
          action: "command",
          command: [
            {
              agent: "addToCart",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            window.location.href = "/cart";
          } else {
            toast.info(result?.data?.message, {
              icon: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="60"
                  height="60"
                  viewBox="0 0 30 30"
                >
                  <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
                </svg>
              ),
              className: "custom-toast text-uppercase",
              bodyClassName: "custom-toast",
            });
          }
        })
        .catch((err) => {
          toast.error("Something went wrong", err);
        });
    }
  };

  const handleMonthChange = ({ activeStartDate }: any) => {
    const monthName = format(activeStartDate, "MMMM"); // Get the month name
    setSelectedMonth(monthName);
  };

  const handleModeType = (type: any) => {
    if (type !== modeType) {
      setSelectedMode(true);
    } else {
      setSelectedMode(!selectedMode);
    }
    setModeType(type);
  };

  const getGlobalTimeZones = async () => {
    const timezones: any = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        action: "command",
        command: [
          {
            agent: "timezoneList",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      }
    );
    const response: any = await timezones?.data?.data;
    setTimeZones(response);
  };

  const mappedOptions =
    timeZones &&
    timeZones
      .sort((a: any, b: any) => a?.timezone.localeCompare(b?.timezone))
      .map((option: any) => ({
        value: option?.timezone,
        label: option?.timezone,
      }));

  const handleTimeZone = (selectedOption: any) => {
    setSelectedTimeZone(selectedOption);
    localStorage.setItem(
      "userSelectedTimezone",
      JSON.stringify(selectedOption)
    );
  };

  const checkSelectedAppointments = async () => {
    if (Object.entries(selectedBookedType).length !== selectedPlan) {
      return setAlertUser(true);
    }
    setSelectedProduct(false);
    setProductDuration(0);
    setProductUuid(0);
    setProductId(0);
    setSelectedSlot("");
    let currentTz: any = "";

    const getTimeZoneFromStorage: any = localStorage.getItem(
      "userSelectedTimezone"
    );
    const parseTz =
      getTimeZoneFromStorage && JSON.parse(getTimeZoneFromStorage);
    if (!getTimeZoneFromStorage) {
      const response = await axios.get("https://ipapi.co/json/");
      if (response?.data && response?.data?.timezone) {
        currentTz = {
          value: response?.data?.timezone,
          label: response?.data?.timezone,
        };
      }
      setSelectedTimeZone(currentTz);
      localStorage.setItem("userSelectedTimezone", JSON.stringify(currentTz));
    } else {
      setSelectedTimeZone(parseTz);
    }

    const bookLaterArray = Object.values(selectedBookedType);
    const isExistBookLater = bookLaterArray.every((elem: any) => elem === 2);
    if (!isExistBookLater) {
      await getSpecialistBookingSlots(startDate, endDate);
    }
  };
  const userToken = localStorage.getItem("token");

  const loadDoctorRating = async () => {
    try {
      const response = await getDoctorRating({
        doctorId: specialistData.id,
      });

      if (response.statusCode === 200) {
        setRatingData(response.data);
      }
    } catch (error) {
      // Handle error
    }
  };

  const buySubscription = async () => {
    let userInfo: any = localStorage.getItem("userInfo");
    let parsedUserInfo =
      typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
    if (
      (parsedUserInfo && parsedUserInfo?.role === "admin") ||
      (parsedUserInfo && parsedUserInfo?.role === "specialist")
    ) {
      localStorage.clear();
    }
    const bookNowLength = Object.values(selectedBookedType || {}).filter(
      (elem: any) => elem === 1
    ).length;
    if (bookNowLength !== selectedAppointments.length) {
      return toast.info(
        `PLEASE SELECT ${bookNowLength}  ${
          bookNowLength === 1 ? "SLOT" : "SLOTS"
        }`,
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="60"
              height="60"
              viewBox="0 0 30 30"
            >
              <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
            </svg>
          ),
          className: "custom-toast",
          bodyClassName: "custom-toast",
        }
      );
    }
    const token = localStorage.getItem("token");

    let userDetails = {};
    if (
      selectedTimezone &&
      stripePriceId &&
      selectedProductUuid &&
      selectedModeType &&
      selectedPlan &&
      subDuration &&
      selectedAppointments &&
      selectedBookedType &&
      specialistData &&
      subscriptionProductId &&
      categoryID
    ) {
      userDetails = {
        selectedTimezone: selectedTimezone.label,
        stripePriceId: stripePriceId,
        productUuid: selectedProductUuid,
        modeType: selectedModeType,
        selectedPlan: selectedPlan,
        duration: subDuration,
        selectedAppointments: selectedAppointments,
        selectedBookedType: selectedBookedType,
        specialistData: specialistData,
        subscriptionProductId: subscriptionProductId,
        categoryUuid: categoryID,
      };
    }

    const encodedData = encodeURIComponent(JSON.stringify(userDetails));

    async function createSubscription(
      decodedSubscriptionData: any,
      token: any
    ) {
      console.log(decodedSubscriptionData);
      setNavigatingToStripe(true);
      const transformedAppointments: any =
        decodedSubscriptionData?.selectedAppointments.map(
          (appointment: any) => ({
            noOfAppointmentPerMonth: `recStripePrice${decodedSubscriptionData?.selectedPlan}ApntPerMonth`,
            priceId:
              decodedSubscriptionData?.selectedPlan === 2
                ? decodedSubscriptionData?.stripePriceId
                    ?.recStripePrice2ApntPerMonth
                : decodedSubscriptionData?.stripePriceId
                    ?.recStripePrice4ApntPerMonth,
            bookingSlotId: appointment.bookingSlotId,
            productId: `${decodedSubscriptionData?.subscriptionProductId}`,
            doctorId: appointment.doctorId,
            StartTime: appointment.StartTime,
            EndTime: appointment.EndTime,
            Duration: appointment.Duration,
            Day: appointment.calendarDay,
            Date: appointment.calendarDate,
            categoryid: categoryID,
            modeType: selectedModeType,
            timezone: decodedSubscriptionData?.selectedTimezone,
            meta_type: "subscription",
            bookStatus: "1",
          })
        );
      Object.entries(decodedSubscriptionData?.selectedBookedType || {}).forEach(
        ([key, value]: [any, any]) => {
          if (value === 2) {
            transformedAppointments.push({
              noOfAppointmentPerMonth: `recStripePrice${decodedSubscriptionData?.selectedPlan}ApntPerMonth`,
              priceId:
                decodedSubscriptionData?.selectedPlan === 2
                  ? decodedSubscriptionData?.stripePriceId
                      ?.recStripePrice2ApntPerMonth
                  : decodedSubscriptionData?.stripePriceId
                      ?.recStripePrice4ApntPerMonth,
              productId: `${decodedSubscriptionData?.subscriptionProductId}`,
              doctorId: decodedSubscriptionData?.specialistData?.id,
              bookingSlotId: null,
              StartTime: null,
              EndTime: null,
              Duration: decodedSubscriptionData?.duration,
              Day: null,
              Date: null,
              categoryid: categoryID,
              modeType: selectedModeType,
              timezone: selectedTimezone.label,
              meta_type: "subscription",
              bookStatus: "0",
            });
          }
        }
      );
      axios
        .post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            ip: "127.0.0.1",
            timezone: decodedSubscriptionData?.selectedTimezone,
            organizationId: "1",
            qty: 1,
            noOfAppointmentPerMonth: `recStripePrice${decodedSubscriptionData?.selectedPlan}ApntPerMonth`,
            priceId:
              decodedSubscriptionData?.selectedPlan === 2
                ? decodedSubscriptionData?.stripePriceId
                    ?.recStripePrice2ApntPerMonth
                : decodedSubscriptionData?.stripePriceId
                    ?.recStripePrice4ApntPerMonth,
            customerId: parsedUserInfo?.customerId,
            productId: `${decodedSubscriptionData?.subscriptionProductId}`,
            doctorId: decodedSubscriptionData?.specialistData?.id,
            success_url: `${process.env.REACT_APP_LOCALHOST_URL}/success/${decodedSubscriptionData?.subscriptionProductId}`,
            cancel_url: `${process.env.REACT_APP_LOCALHOST_URL}/cancel`,
            metadata: transformedAppointments,
            cartUuid: "",
            cartId: "",
            action: "command",
            command: [
              {
                agent: "createSubscriptionCart",
                appName: "selfMaximized",
                folder: "order",
              },
              {
                agent: "addToCartSubscribe",
                appName: "selfMaximized",
                folder: "order",
              },
              {
                agent: "createOrder",
                appName: "selfMaximized",
                folder: "order",
              },
              {
                agent: "createSubscription",
                appName: "selfMaximized",
                folder: "order",
              },
            ],
          },
          {
            headers: { "x-access-token": token },
          }
        )
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            setNavigatingToStripe(false);
            return (window.location.href = res?.data?.data?.sessionUrl);
          } else {
            setNavigatingToStripe(false);
            return false;
          }
        })
        .catch((err) => {
          setNavigatingToStripe(false);
        });
    }

    if (!token) {
      return (window.location.href = `/app/login?callback=registersubscription&data=${encodedData}`);
    } else if (parsedUserInfo?.role === "user" && token) {
      createSubscription(userDetails, token);
    }
  };

  const bookSlotOfSubscription = () => {
    if (
      selectedProductUuid === "" ||
      selectedModeType === "" ||
      selectedPlan === 0
    ) {
      setWarningStatus(true);
    }
    if (isSubscriptionChecked) {
      if (
        selectedPlan === 0 &&
        selectedModeType === "" &&
        selectedProductUuid === ""
      ) {
        return true;
      } else {
        const modal = document.getElementById("appSelectedModalOpen");
        setConsumerChoice("subscription");
        if (modal) {
          setTimeout(() => {
            const modalInstance = new Modal(modal);
            modalInstance.show();
          }, 100);
          return;
        }
      }
    }
  };

  const closeModal = (type: string) => {
    const modalElement = document.getElementById(type);

    if (modalElement) {
      const modalInstance =
        Modal.getInstance(modalElement) || new Modal(modalElement);

      modalElement.addEventListener("hidden.bs.modal", () => {
        const backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
          backdrop.remove();
        }
        document.body.classList.remove("modal-open");
      });

      modalInstance.hide();
    }
  };

  //COMPONENTS
  const BookSingular = () => {
    return (
      <div
        className={`col singular-appointment-wrapper singular-appointment-wrapper-new  ${
          isChecked || modeType || productId ? "active" : ""
        } cursor_pointer`}
        onClick={() => {
          setIsChecked(!isChecked);
          setWarningStatus(false);
        }}
      >
        <div className="mb-3 form-check form-check-custom pe-4">
          <input
            className="form-check-input  custom-checkbox custom-checkbox-new mb-1"
            type="checkbox"
            style={{
              width: "16px",
              height: "16px",
              outline: "none",
              borderRadius: "0",
            }}
            id="exampleCheck1"
            onClick={() => {
              setIsChecked(!isChecked);
              setWarningStatus(false);
              setUndoAllSub((prev) => !prev);
            }}
            checked={isChecked || productId}
          />
          <label className="form-check-label mb-0" htmlFor="exampleCheck1">
            {bookSpecialistData?.props?.singularAppointmentSelection ??
              "SINGULAR APPOINTMENT OPTIONS :"}
          </label>
          <p className="explore-heading-one text-white text-uppercase">
            {bookSpecialistData?.props?.singularAppointmentValidity ??
              " valid for 30 days"}
          </p>
          <p
            className={`explore-heading-one text-uppercase ${
              !productKeyId && singularWarningStatus
                ? "text-danger"
                : "text-white"
            }`}
          >
            {bookSpecialistData?.props?.appointmentSelection ??
              "Please select appointment"}
          </p>
        </div>
        <div className="px-4">
          {productData.length !== 0 && productData !== null
            ? productData.map((elem: any) => (
                <>
                  <div
                    className={`d-md-flex justify-content-between align-items-center py-2 px-3 mb-2 cursor_pointer`}
                    onClick={() => {
                      selectProduct(elem);
                      setUndoAllSub((prev) => !prev);
                      setIsSubscriptionChecked(false);
                    }}
                    key={elem.id}
                    style={{
                      border: `${
                        productId !== elem.uuid ? "1px solid white" : ""
                      }`,
                      backgroundColor: `${
                        selectedProduct && productId === elem.uuid
                          ? "white"
                          : ""
                      }`,
                    }}
                  >
                    <div className="">
                      <p
                        className="mb-0 appointment-text-one"
                        style={{
                          color: `${productId !== elem.uuid ? "white" : ""}`,
                        }}
                      >
                        {parseFloat(elem.duration).toFixed(2) === "1.00"
                          ? 60
                          : 30}
                        <span className="ps-1">
                          {bookSpecialistData?.props?.productDuration ??
                            "MIN VIRTUAL APPOINTMENT"}
                        </span>{" "}
                      </p>
                    </div>
                    <div className="">
                      <div className="package-amount-in-dollar-one px-3 py-1">
                        <div className="d-flex align-items-center">
                          <span
                            className="pe-1"
                            style={{
                              color: productId !== elem.uuid ? "white" : "",
                            }}
                          >
                            <i className="fa-solid fa-dollar-sign"></i>
                          </span>
                          <span
                            className="amount-dollar_package-one letter-spacing-pricing"
                            style={{
                              color: productId !== elem.uuid ? "white" : "",
                            }}
                          >
                            {elem.price}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            : null}

          {productData.length !== 0 && productData !== null ? (
            <>
              <div className="py-3">
                <p
                  className={`${
                    !modeType && singularWarningStatus
                      ? "text-danger"
                      : "text-light"
                  } mb-0 font-18 please-select-mode`}
                >
                  {bookSpecialistData?.props?.productMode ??
                    "PLEASE SELECT MODE OF APPOINTMENT"}
                </p>
              </div>
              <div className="appointment-duration-box-inner mb-3">
                {[
                  {
                    type: "video",
                    icon: "fa-video",
                    label: "Video",
                  },
                  {
                    type: "audio",
                    icon: "fa-headphones",
                    label: "Audio",
                  },
                  {
                    type: "chat",
                    icon: "fa-message",
                    label: "Chat",
                  },
                ].map(
                  ({
                    type,
                    icon,
                    label,
                  }: {
                    type: string;
                    icon: string;
                    label: string;
                  }) => (
                    <span
                      key={type}
                      className="appointment-custom-box custom-font-style custom-font-style-v1 text-uppercase"
                      onClick={() => {
                        handleModeType(type);
                        setIsChecked(true);
                      }}
                      style={{
                        color:
                          selectedMode && modeType === type ? "black" : "white",
                        backgroundColor:
                          selectedMode && modeType === type ? "white" : "black",
                      }}
                    >
                      <i
                        className={`fa-solid ${icon} text-${
                          selectedMode && modeType === type ? "dark" : "white"
                        }`}
                      ></i>{" "}
                      {label}
                    </span>
                  )
                )}
              </div>
            </>
          ) : (
            <span className="text-white letter_spacing ">
              NO PRODUCTS AVAILABLE
            </span>
          )}
        </div>
      </div>
    );
  };

  const RenderSlots = () => {
    return slots.map((slot: any, index: any) => (
      <div
        key={index}
        className="d-flex flex-wrap gap-4 justify-content-between mt-3 mb-3"
      >
        <h3 className="text-white explore-heading-two flex-100 mb-0 line-height-20 mt-3">
          {slot.dayOfWeek}
        </h3>
        {slot?.slots
          .sort((a: any, b: any) => {
            const timeA = moment(
              a.StartTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
              "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
            ).tz(selectedTimezone?.value || userTimezone);

            const timeB = moment(
              b.StartTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
              "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
            ).tz(selectedTimezone?.value || userTimezone);

            // Prioritize AM before PM
            if (timeA.format("A") !== timeB.format("A")) {
              return timeA.format("A") === "AM" ? -1 : 1;
            }

            // Sort by time within the same period
            return timeA.diff(timeB);
          })
          .map((elem: any) => {
            const isSelected = selectedAppointments.find(
              (appointment: any) =>
                appointment.StartTime === elem?.StartTime &&
                appointment.EndTime === elem?.EndTime &&
                appointment.calendarDate === elem?.calendarDate
            );
            return (
              <div
                className="slot-box slot-box-new col-md-6 cursor_pointer"
                key={elem.uuid}
                onClick={() => passSlots(elem)}
              >
                <div
                  className="slot-box-inner border pt-2 pb-2 ps-3 pe-3"
                  style={{
                    backgroundColor:
                      selectedSlot === elem ||
                      (isSelected?.StartTime === elem?.StartTime &&
                        isSelected?.EndTime === elem?.EndTime &&
                        isSelected?.calendarDate === elem?.calendarDate)
                        ? "white"
                        : "",
                    color:
                      selectedSlot === elem ||
                      (isSelected?.StartTime === elem?.StartTime &&
                        isSelected?.EndTime === elem?.EndTime &&
                        isSelected?.calendarDate === elem?.calendarDate)
                        ? "black"
                        : "",
                  }}
                >
                  <h5 className="mb-0 explore-heading-two pb-0">
                    AVAILABILITY | {elem?.Duration} HR
                  </h5>
                  <p
                    className="mb-0 explore-heading-two pb-0"
                    style={{
                      color:
                        selectedSlot === elem ||
                        (isSelected?.StartTime === elem?.StartTime &&
                          isSelected?.EndTime === elem?.EndTime &&
                          isSelected?.calendarDate === elem?.calendarDate)
                          ? "black"
                          : "white",
                    }}
                  >
                    {selectedTimezone?.value && elem?.StartTime
                      ? moment
                          .tz(
                            elem.StartTime.replace(/:000Z$/, ":00Z"),
                            selectedTimezone?.value || userTimezone
                          )
                          .format("hh:mm A")
                      : "Date Not Available"}
                    <span className="to"> TO </span>
                    {selectedTimezone?.value && elem?.EndTime
                      ? moment
                          .tz(
                            elem.EndTime.replace(/:000Z$/, ":00Z"),
                            selectedTimezone?.value || userTimezone
                          )
                          .format("hh:mm A")
                      : "Date Not Available"}
                  </p>
                  <p
                    className="mb-0 explore-heading-two pb-0"
                    style={{
                      color:
                        selectedSlot === elem ||
                        (isSelected?.StartTime === elem?.StartTime &&
                          isSelected?.EndTime === elem?.EndTime &&
                          isSelected?.calendarDate === elem?.calendarDate)
                          ? "black"
                          : "white",
                    }}
                  >
                    {selectedTimezone?.value && elem?.StartTime
                      ? moment
                          .tz(elem.StartTime.replace(/:000Z$/, ":00Z"), "UTC")
                          .tz(selectedTimezone?.value || userTimezone)
                          .format("YYYY-MM-DD")
                      : "Date Not Available"}
                    <span className="day-name-right floar-right">
                      {selectedTimezone?.value && elem?.StartTime
                        ? moment
                            .tz(elem.StartTime.replace(/:000Z$/, ":00Z"), "UTC")
                            .tz(selectedTimezone?.value || userTimezone)
                            .format("dddd")
                        : "Date Not Available"}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    ));
  };

  const TimeZone = () => {
    return (
      <div id="selfDropdown">
        <label className="letter_spacing">
          {(() => {
            const bookingValues = Object.values(selectedBookedType);
            const bookNowCount = bookingValues.filter(
              (elem) => elem === 1
            ).length;
            const bookLaterCount = bookingValues.filter(
              (elem) => elem === 2
            ).length;

            const slotText = bookNowCount === 1 ? "SLOT" : "SLOTS";
            const isAreText = bookLaterCount === 1 || 0 ? "IS" : "ARE";

            return (
              <label className="letter_spacing">
                {consumerChoice === "subscription" &&
                  `NOTE - NOW YOU CAN BOOK ${bookNowCount} ${slotText} AS ${bookLaterCount} ${isAreText} OF BOOK LATER`}
              </label>
            );
          })()}
        </label>
        <label className="available-slot d-block h6 text-start text-uppercase text-light letter_spacing">
          {bookSlotPopUpData?.props?.selectTimeZoneText ?? "SELECT TIMEZONE"}
        </label>
        <label className="available-slot d-block h6 text-start text-uppercase text-light letter_spacing">
          {bookSlotPopUpData?.props?.selectTimeZoneNote ??
            ` NOTE - The default timezone is set according to your country. If you
          choose a time slot based on a different country's timezone, please
          ensure that you are present in that country at the scheduled time.`}
        </label>
        <Select
          value={selectedTimezone}
          onChange={handleTimeZone}
          options={mappedOptions}
          isSearchable={true}
          name="areaOfExp"
          className="text-dark letter_spacing col-lg-4 mb-4 text-uppercase"
          styles={{
            control: (provided: any, state: any) => ({
              ...provided,
              fontSize: "16px",
              color: "inherit",
              backgroundColor: "#fff",
              letterSpacing: "1px",
              border: state.isFocused
                ? "2px solid black"
                : "2px solid transparent",
              boxShadow: state.isFocused ? "none" : "none",
              "&:hover": {
                borderColor: "black",
              },
            }),
            option: (provided: any, state: any) => ({
              ...provided,
              color: state.isFocused || state.isSelected ? "white" : "black",
              backgroundColor: state.isFocused ? "#000" : "#fff",
            }),
            singleValue: (provided: any) => ({
              ...provided,
              color: "#000",
            }),
            input: (provided: any) => ({
              ...provided,
              color: "black",
            }),
            placeholder: (provided: any) => ({
              ...provided,
              color: "white",
            }),
            menu: (provided: any) => ({
              ...provided,
              backgroundColor: "#000",
            }),
          }}
          placeholder="SEARCH..."
        />
      </div>
    );
  };

  return (
    <div>
      <input
        className="next-btn"
        type="hidden"
        id="popUpModal"
        data-bs-toggle="modal"
        data-bs-target="#specialistDetails"
        ref={specialistDetails}
      />
      <input
        className="next-btn"
        type="hidden"
        id="bookSlotModal"
        data-bs-toggle="modal"
        data-bs-dismiss="modal"
        data-bs-target="#bookSlotPopUp"
      />
      <input
        className="next-btn"
        type="hidden"
        id="reviewModal"
        data-bs-toggle="modal"
        data-bs-target="#reviewModalOpen"
      />
      <input
        className="next-btn"
        type="hidden"
        id="reviewModal2"
        data-bs-toggle="modal"
        data-bs-target="#reviewModalOpen2"
      />
      <input
        className="next-btn"
        type="hidden"
        id="appSelectedModal"
        data-bs-toggle="modal"
        data-bs-target="#appSelectedModalOpen"
      />
      <section
        className="pb-0 user-registration-flow-wrapper py-0"
        id="bigPopUp"
      >
        <div className="container-fluid left-right-space extra-space-container">
          <div className="row mt-5" id="modalPopUp">
            <div
              className="modal fade user__pop__wrapper p-0 "
              id="specialistDetails"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-fullscreen transform-none transition-none modal-dialog-centered">
                <div className="modal-content py-5 position-relative specialist-pop-up subscribe-popup">
                  <div className="container-fluid container-fluid-custom">
                    <SpecialistInfo specialistData={specialistData} />
                    <div className="col">
                      <div className="subscription-group">
                        <ul className="ps-0">
                          <li className="star-group star-group-outer">
                            <div className="ratting-outer-box">
                              <span className="explore-heading-one text-white d-inline pe-2 vertical-center">
                                {ratingData?.doctorRating[0]?.average_rating
                                  ? parseFloat(
                                      ratingData?.doctorRating[0]
                                        ?.average_rating
                                    ).toFixed(1)
                                  : 0}
                              </span>
                              <Rating
                                size={30}
                                fillColor="white"
                                emptyColor="black"
                                SVGstorkeWidth="1px"
                                SVGstrokeColor="white"
                                allowFraction
                                initialValue={Number(
                                  ratingData?.doctorRating[0]?.average_rating
                                )}
                                style={{
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                }}
                                className="stat-rating"
                              />
                              <span className="explore-heading-one text-white d-inline vertical-center">
                                <a
                                  onClick={handleReviewModal2}
                                  className="text-uppercase text-white d-inline explore-heading-one text-decoration-underline cursor-pointer ps-2 text-decoration-none"
                                >
                                  (
                                  {ratingData?.doctorRating[0]
                                    ?.doctor_total_rating_count
                                    ? ratingData?.doctorRating[0]
                                        ?.doctor_total_rating_count
                                    : "0"}
                                  )
                                </a>
                              </span>
                            </div>
                            <div className="review-outer">
                              <a
                                onClick={handleReviewModal}
                                className="text-uppercase text-white d-inline explore-heading-one text-decoration-underline cursor-pointer text-decoration-none"
                              >
                                Write reviews
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <hr className="text-white mt-0 custom-hr"></hr>

                    <div className="row">
                      <div className="col-lg-12 please-select-text">
                        <h2 className={`text-white text-uppercase mb-0 mb-0`}>
                          {bookSpecialistData?.props?.typeSelectionText ??
                            "please select type"}
                        </h2>
                        <p className="text-white p explore-heading-one text-uppercase">
                          {bookSpecialistData?.props?.selectAllFields ??
                            " Please select all fields before proceeding"}
                          {}
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="appointment-type-wrapper appointment-type-wrapper-box">
                          <div className="row gap-5">
                            <BookSingular />
                            <BookSubscription
                              productData={productData}
                              undoAllSub={undoAllSub}
                              onSelectedRange={handleSelectedData}
                              warningStatus={warningStatus}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 ps-3 pe-3 text-end mt-0 mt-md-5 mt-lg-5 mt-xl-5 book-slot-outer">
                        <div className="px-md-5">
                          {/* <SingularBookSlotButton /> */}
                          {modeType && productId ? (
                            <button
                              className="btn book-btn book-btn-new min-height-40"
                              type="button"
                              onClick={handlBookSlotModal}
                            >
                              BOOK SLOT
                            </button>
                          ) : null}
                          {isSubscriptionChecked &&
                            selectedProductUuid &&
                            selectedModeType &&
                            selectedPlan && (
                              <button
                                className="btn book-btn book-btn-new min-height-40"
                                type="button"
                                {...(selectedPlan !== 0 &&
                                selectedModeType !== "" &&
                                selectedProductUuid !== ""
                                  ? {
                                      "data-bs-target": "#appSelectedModalOpen",
                                      "data-bs-toggle": "modal",
                                      "data-bs-dismiss": "modal",
                                    }
                                  : {})}
                                onClick={bookSlotOfSubscription}
                              >
                                BOOK SLOT
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="cancel__pop__icon cursor-pointer"
                    data-bs-target="#specialistDetails"
                    data-bs-dismiss="modal"
                    id="closeFirstModal"
                    onClick={() => {
                      closeModal("specialistDetails");
                    }}
                  >
                    <i className="fa-solid fa-xmark text-white bg-dark"></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade p-0 gloabl-model-settings"
              id="bookSlotPopUp"
              tabIndex={-1}
            >
              <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content user_response--popup p-4 p-md-5 p-lg-5 p-xl-5 position-relative">
                  <div className="modal-body text-center p-0 mt-5">
                    <label
                      className="form-check-label mb-0 text-white explore-heading-two text-left w-100"
                      htmlFor="exampleCheck1"
                    >
                      <div
                        className="ml-6 pl-5 close__modal__icon cursor_pointer"
                        data-bs-target="#bookSlotPopUp"
                        data-bs-dismiss="modal"
                        id="closeSecondModal"
                        onClick={() => closeModal("bookSlotPopUp")}
                      >
                        <i className="fa-solid fa-xmark text-white"></i>
                      </div>
                      <span className="d-block h4 text-start mb-0">
                        {consumerChoice === "subscription"
                          ? `${
                              bookSlotPopUpData?.props
                                ?.subscriptionAppointmentPlanText ??
                              "SUBSCRIPTION APPOINTMENT PLAN -"
                            }  ${selectedAppointments.length} / ${selectedPlan}`
                          : `${
                              bookSlotPopUpData?.props
                                ?.singularAppointmentOptionText ??
                              "SINGULAR APPOINTMENT OPTIONS"
                            }`}
                      </span>
                      <span className="available-slot d-block h6 text-start">
                        {bookSlotPopUpData?.props?.availableSlotText ??
                          "AVAILABLE SLOTS FOR - DR."}
                        {specialistData?.name?.toUpperCase()}
                      </span>
                    </label>
                    <div className="book-select-options w-100 text-start">
                      {consumerChoice === "singular" && (
                        <div className="book-select-option-inner pb-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio-bookNow"
                              onChange={() => setBookStatus("bookNow")}
                              checked={bookStatus === "bookNow"}
                            />
                            <label
                              className="form-check-label text-white explore-heading-two"
                              htmlFor="inlineRadio-bookNow"
                            >
                              <span className="ps-2">
                                {bookSlotPopUpData?.props?.bookNowTitle ??
                                  "BOOK NOW"}
                              </span>
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio-bookLater"
                              onChange={() => {
                                setBookStatus("bookLater");
                                passSlots("");
                              }}
                              checked={bookStatus === "bookLater"}
                            />
                            <label
                              className="form-check-label text-white explore-heading-two"
                              htmlFor="inlineRadio-bookLater"
                            >
                              <span className="ps-2">
                                {bookSlotPopUpData?.props?.bookLaterTitle ??
                                  "BOOK LATER"}
                              </span>
                            </label>
                          </div>

                          <label className="form-check-label mb-0 text-white explore-heading-two text-left w-100">
                            <span className="available-slot d-block h6 text-start text-uppercase">
                              {bookSlotPopUpData?.props
                                ?.singularBookLaterText ??
                                ` NOTE - If you choose to book later, please ensure
                              to select a slot within the next 30 days.`}
                            </span>
                          </label>
                        </div>
                      )}

                      <TimeZone />

                      {bookStatus === "bookNow" ||
                      Object.values(selectedBookedType).filter(
                        (elem: any) => elem === 1
                      ).length !== 0 ? (
                        <div className="row border-top m-0 ">
                          <div className="col-md-12 col-lg-4 border-end pt-4 pb-4 pe-lg-4 pe-md-4 pe-0 ps-0 react-calendar-outer">
                            <h6 className="mb-0 text-uppercase text-white explore-heading-two pb-2">
                              {bookSlotPopUpData?.props?.selectDateText ??
                                ` Please select the date before we proceed`}
                            </h6>
                            <Calendar
                              selectRange={true}
                              onChange={handleRangeSelection}
                              value={[startDate, endDate]}
                              onActiveStartDateChange={handleMonthChange} // Handle month change
                            />
                          </div>
                          <div className="col-md-12 col-lg-8 ps-0 ps-md-4 ps-lg-4 pe-0 pt-4 pb-4">
                            <h5 className="h6 text-uppercase text-white explore-heading-two explore-heading-two-box">
                              {bookSlotPopUpData?.props
                                ?.availableTimeSlotText ??
                                `Available time slots for the month of`}
                              {selectedMonth}
                            </h5>
                            <div className="book-overflow">
                              <div className="slot-outer text-uppercase text-white">
                                <div className="slot-inner row g-3">
                                  <RenderSlots />
                                </div>
                              </div>

                              <hr className="custom-hr custom-hr-white text-white"></hr>
                              {slots.length === 0 && (
                                <h4 className="text-white text-center letter_spacing text-uppercase">
                                  No slots available for selected date{" "}
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div
                    className="mr-5 text-white back__btn_left"
                    data-bs-target="#specialistDetails"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setSelectedAppointments([]);
                    }}
                  >
                    <button
                      className="btn book-btn book-btn-new min-height-40"
                      type="button"
                    >
                      {bookSlotPopUpData?.props?.backButtonText ?? "BACK"}
                    </button>
                  </div>

                  <div className="submit-btn-custom w-100 d-flex justify-content-end">
                    {/* <RenderSubmitButton /> */}
                    {consumerChoice === "subscription" ? (
                      <>
                        {selectedAppointments.length !== 0 && (
                          <button
                            className="btn book-btn book-btn-new d-block mb-0 mt-0 min-height-40 me-2 "
                            data-bs-target="#viewSelectedSlots"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            data-bs-backdrop="false"
                            onClick={() => {
                              const m =
                                document.getElementById("viewSelectedSlots");
                              if (m) {
                                const modal = new Modal(m, { backdrop: false });
                                modal.show();
                              }
                            }}
                          >
                            {bookSlotPopUpData?.props?.viewSelectedSlotText ??
                              " VIEW SELECTED SLOTS"}
                          </button>
                        )}

                        <button
                          className="btn book-btn book-btn-new d-block mb-0 mt-0 min-height-40"
                          onClick={buySubscription}
                          disabled={navigatingToStripe}
                        >
                          {navigatingToStripe
                            ? "LOADING..."
                            : `${
                                bookSlotPopUpData?.props
                                  ?.subscriptionBuyNowText ?? "BUY NOW"
                              }`}
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn book-btn book-btn-new d-block mb-0 mt-0 min-height-40"
                        type="button"
                        onClick={createCart}
                      >
                        SUBMIT
                      </button>
                    )}
                    <input
                      data-bs-target="#specialistDetails"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      id="dismissModal"
                      type="hidden"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade p-0 gloabl-model-settings"
        id="viewSelectedSlots"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content user_response--popup p-4 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center p-0 mt-5 d-flex justify-content-between">
              <ViewSelectedSlots
                consumerChoice={consumerChoice}
                selectedAppointments={selectedAppointments}
                selectedPlan={selectedPlan}
                specialistData={specialistData}
                selectedTimezone={selectedTimezone}
                userTimezone={userTimezone}
              />
            </div>
          </div>
        </div>
      </div>
      <WriteReview
        globalRating={ratingData?.doctorRating[0]?.total_rating_count}
        reviewData={reviewData}
        setReviewData={setReviewData}
        onSubmit={handleReviewSubmit}
      />

      <ViewReview ratingData={ratingData} />
      <div
        className="modal row fade  p-0 gloabl-model-settings"
        id="appSelectedModalOpen"
        aria-hidden="true"
        tabIndex={-1}
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content user_response--popup p-4 p-md-5 p-lg-5 p-xl-5 position-relative">
            <div className="modal-body text-center p-0">
              <div className="col-md-12">
                <BookedAppointment
                  selectedProductUuid={selectedProductUuid}
                  selectedModeType={selectedModeType}
                  selectedPlan={selectedPlan}
                  subDuration={productDuration}
                  selectedSlot={selectedSlot}
                  specialistData={specialistData?.name}
                  selectedMonth={selectedMonth}
                  nextProductBookedType={nextProductBookedType}
                  selectedAppointments={selectedAppointments}
                  onSelectedBookedtype={handleBookedType}
                  checkSelectedBookedType={handlecheckSelectedBookedType}
                  alertUser={alertUser}
                />

                <button
                  className="btn book-btn book-btn-new min-height-40"
                  {...(Object.entries(selectedBookedType).length ===
                  selectedPlan
                    ? {
                        "data-bs-target": "#bookSlotPopUp",
                        "data-bs-toggle": "modal",
                        "data-bs-dismiss": "modal",
                      }
                    : {})}
                  onClick={checkSelectedAppointments}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookSpecialist;
