import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import usePuckEditorByPageName from "../customHooks/usePuckEditorByPageName";

const Journal: React.FC = () => {
  const [journalData, setJournalData] = useState<any>([]);
  const [newJournalData, setNewJournalData] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [allLoader, setAllLoader] = useState<any>(false);
  const [page, setPage] = useState<any>(0);
  const [blogs, setNoOfBlogs] = useState<any>([]);
  const [activeCategory, setActiveCategory] = useState<any>(
    blogs[0]?.category_name || ""
  );
  const [otherBlogs, setOtherBlogs] = useState<any>(false);
  const [visibleBlogs, setVisibleBlogs] = useState<any>([]);
  const [remainingBlogs, setRemainingBlogs] = useState<any>([]);
  const containerRef = useRef<any>(null);
  const viewMore = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [hover, setHover] = useState<any>(false);
  const [firstCategory, setFirstCategoryName] = useState<any>("");
  const [allText, setAllText] = useState<any>("ALL");
  const { puckData, puckloader } = usePuckEditorByPageName("JOURNAL");
  const jrnlPuckData: any = puckData?.content?.find(
    (elem: any) => elem.type === "Journal"
  );

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllCategories = async () => {
    let categoryName: any = "";
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        category_by_count: true,
        blogId: "",
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "blogListByCategory",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setNoOfBlogs(result?.data?.data);
          categoryName = result?.data?.data[0].category_name;
          setFirstCategoryName(categoryName);
          if (categoryName) {
            getJournals(categoryName);
          }
        } else {
          setNoOfBlogs([]);
        }
      })
      .catch((err) => {
        if (err) {
          return err;
        }
      });
  };
  const getJournals = async (categoryName: any) => {
    setLoader(true);

    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        category: activeCategory || categoryName,
        blogId: "",
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "blogListByCategory",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        const newData = result?.data?.data[0].blog_data;
        setJournalData(newData);
        setLoader(false);
        setOtherBlogs(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    getMorePosts();
  }, [allText]);

  useEffect(() => {
    if (allLoader) {
      getMorePosts();
    }
  }, [allLoader, allText]);

  useEffect(() => {
    const updateVisibleBlogs = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const buttonWidth = 150; // Assume a fixed button width for calculation
        const maxButtons = Math.floor(containerWidth / buttonWidth);
        setVisibleBlogs(blogs.slice(0, maxButtons));
        setRemainingBlogs(
          blogs.filter((elem: any, index: any) => {
            return index >= maxButtons;
          })
        );
      }
    };

    updateVisibleBlogs();
  }, [blogs]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        viewMore.current &&
        !viewMore.current.contains(event.target as Node)
      ) {
        setOtherBlogs(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getJournalsCB = useCallback(() => {
    getJournals("");
  }, [activeCategory]);

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    getJournalsCB();
  }, [getJournalsCB]);

  useEffect(() => {
    setJournalData([]);
  }, [allText !== "ALL"]);

  useEffect(() => {
    setNewJournalData([]);
  }, [allText === "ALL"]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !=
      document.documentElement.offsetHeight
    ) {
      if (!allLoader) {
        setAllLoader(true); // Trigger data fetch if not currently loading
      }
    }
  };

  const getAllJournals = async (pageNumber: any) => {
    if (allText === "ALL") {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          filter: {
            filter: {
              logic: "and",
              offset: (pageNumber - 1) * 4,
              limit: 4,
              filters: [
                {
                  field: "publish",
                  operator: "eq",
                  value: "1",
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "blogList",
              appName: "selfMaximized",
              folder: "blogs",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            const newData = result?.data?.data;
            setNewJournalData((prevData: any) => [...prevData, ...newData]);
          } else {
            setNewJournalData([]);
          }
        })
        .finally(() => setAllLoader(false));
    }
  };

  const getMorePosts = () => {
    setPage((page: any) => {
      const newPage = page + 1;
      getAllJournals(newPage);
      return newPage;
    });
  };

  const styles: any = {
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      overflow: "hidden",
    },
    scrollContainer: {
      display: "flex",
      overflowX: "auto",
      scrollBehavior: "smooth",
      scrollbarWidth: "none",
      scrollbarHeight: "none",
      whiteSpace: "nowrap",
    },
    categoryItem: {
      padding: "5px 10px ",
      whiteSpace: "nowrap",
    },
    button: {
      cursor: "pointer",
    },
  };

  return (
    <>
      <section
        className="custom-pt-1 custom-pb-2 parallaxie journal-detail-page-wrapper h-100 overflow-auto"
        onScroll={handleScroll}
      >
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner">
                <h1 className="mb-0 banner-heading">
                  {jrnlPuckData?.props?.title
                    ? jrnlPuckData?.props?.title
                    : "JOURNAL"}
                </h1>
                <p className="mb-0 banner-description text-uppercase">
                  {jrnlPuckData?.props?.description
                    ? jrnlPuckData?.props?.description
                    : "Medcity has been present in Europe since 1990, offering innovative solutions, specializing in medical services for treatment of medical infrastructure."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page-content">
        <section className="py-0">
          <div className="container-fluid">
            <div className="row">
              <div className="blog_page_section mb-140">
                <div
                  className="blog_messonry_button d-flex justify-content-between align-items-center cust-tab-width"
                  id="jornalTab"
                >
                  <div style={styles.container} className="position-relative">
                    <div className="tabs-left_arrow">
                      <button
                        onClick={scrollLeft}
                        style={{
                          ...styles.button,
                          color: hover ? "white" : "grey",
                        }}
                        className=""
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.707 4.707a1 1 0 0 1 0 1.414L8.414 8l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0z" />
                        </svg>
                      </button>
                    </div>
                    <div ref={scrollRef} style={styles.scrollContainer}>
                      <button
                        style={styles.categoryItem}
                        className={`${
                          activeCategory ? "" : "active"
                        } text-uppercase`}
                        onClick={() => {
                          setJournalData([]);
                          setAllText("ALL");
                          setActiveCategory("");
                          setPage(0);
                          setLoader(false);
                        }}
                      >
                        ALL
                      </button>

                      {blogs.map((category: any, index: any) => (
                        <button
                          key={index}
                          style={styles.categoryItem}
                          onMouseEnter={() => setHover(true)}
                          onMouseLeave={() => setHover(false)}
                          className={`${
                            activeCategory &&
                            activeCategory === category.category_name &&
                            !firstCategory
                              ? "active"
                              : ""
                          } text-uppercase`}
                          onClick={() => {
                            setActiveCategory(category.category_name);
                            setFirstCategoryName("");
                            setNewJournalData([]);
                            if (!allText && activeCategory) {
                              setJournalData([]);
                            }
                            if (allText) {
                              setAllText("");
                            }
                          }}
                        >
                          {category.category_name}({category.blog_count})
                        </button>
                      ))}
                    </div>
                    <div className="tabs-right_arrow">
                      <button
                        onClick={scrollRight}
                        style={{
                          ...styles.button,
                          color: hover ? "white" : "grey",
                        }}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.293 12.293a1 1 0 0 1 0-1.414L9.586 8 6.293 4.707a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row blog_page_gallery">
                  {allText !== "ALL" &&
                  journalData.length !== 0 &&
                  journalData !== null ? (
                    journalData.map((elem: any) => (
                      <div
                        className="col-lg-6 position-relative px-0"
                        key={elem.id}
                      >
                        <Link
                          to={`/journaldetails/${elem.id}`}
                          state={elem}
                          className="journal-box"
                        >
                          <div className="journal-thumb">
                            <div className="cs-image">
                              <img
                                src={elem.image}
                                alt="journal"
                                className="img-fluid w-100 main-img"
                                style={{ width: "auto", height: "520px" }}
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="journal-content">
                            <p className="journal-date-title mb-0">
                              {elem.title.toUpperCase()}
                            </p>
                            <p className="mb-0 title-of-the-journal journal-blog__title py-1">
                              <span className="d-block">
                                {elem.summary.toUpperCase()}
                              </span>
                            </p>
                            <Link
                              to={
                                elem?.otherAuthor === 0
                                  ? `/buyproduct/${
                                      elem?.author ? elem?.author_uuid : 0
                                    }/0`
                                  : `/categories/${
                                      elem?.category &&
                                      elem?.category[0]?.categoryid
                                    }/${
                                      elem?.category && elem?.category[0]?.uuid
                                    }`
                              }
                            >
                              <p className="journal-date-title mb-0 text-uppercase text-decoration-underline">
                                BY. {elem?.author ? elem?.author : "ADMIN"}
                              </p>
                            </Link>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : allText !== "ALL" && journalData.length === 0 ? (
                    <span className="text-center text-white letter_spacing">
                      NO DATA AVAILABLE
                    </span>
                  ) : allText === "ALL" ? (
                    newJournalData.map((elem: any) => (
                      <div
                        className="col-lg-6 position-relative px-0"
                        key={elem.id}
                      >
                        <Link
                          to={`/journaldetails/${elem.id}`}
                          state={elem}
                          className="journal-box"
                        >
                          <div className="journal-thumb">
                            <div className="cs-image">
                              <img
                                src={elem.image}
                                alt="journal"
                                className="img-fluid w-100 main-img"
                                style={{ width: "auto", height: "520px" }}
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="journal-content">
                            <p className="journal-date-title mb-0">
                              {elem.title.toUpperCase()}
                            </p>
                            <p className="mb-0 title-of-the-journal journal-blog__title py-1">
                              <span className="d-block">
                                {elem.summary.toUpperCase()}
                              </span>
                            </p>
                            <Link
                              to={
                                elem?.otherAuthor === 0
                                  ? `/buyproduct/${
                                      elem?.author ? elem?.author_uuid : 0
                                    }/0`
                                  : `/categories/${
                                      elem?.category &&
                                      elem?.category[0]?.categoryid
                                    }/${
                                      elem?.category && elem?.category[0]?.uuid
                                    }`
                              }
                            >
                              <p className="journal-date-title mb-0 text-uppercase text-decoration-underline">
                                BY. {elem?.author ? elem?.author : "ADMIN"}
                              </p>
                            </Link>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : allText === "ALL" && newJournalData.length === 0 ? (
                    <span className="text-uppercase text-white letter_spacing">
                      NO DATA AVAILABLE
                    </span>
                  ) : (
                    <span className="text-uppercase text-white letter_spacing">
                      NO DATA AVAILABLE
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Journal;
